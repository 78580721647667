/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Mon Nov 15 2021
 * Category: Pages
 * Title: SolutionsBanner
 *
 */
.solutions-banner {
  position: relative;
  padding: 100px 0 70px;
  min-height: 750px;
  max-height: 750px; }
  .solutions-banner:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: url("https://www.cubix.co/themes/responsiv-clean/assets/images/cubix-happyforce/cubix-happyforce-bg.jpg") #007eff no-repeat;
    background-size: cover;
    background-position: center; }
  .solutions-banner .solutions-banner-caption {
    position: relative;
    z-index: 2;
    padding-top: 60px;
    padding-right: 60px; }
  .solutions-banner .title {
    font-size: 45px;
    font-weight: 700;
    line-height: 1.25;
    color: #fff; }
    .solutions-banner .title span {
      color: #fff; }
  .solutions-banner .description {
    font-size: 21px;
    font-weight: 500;
    color: #fff;
    line-height: 1.5;
    margin: 25px 0 45px; }
    .solutions-banner .description span {
      color: #fff; }
  .solutions-banner .btn {
    min-height: 57px;
    min-width: 200px;
    font-weight: 600;
    font-size: 17px;
    text-transform: uppercase;
    border-radius: 2px;
    margin-bottom: 30px; }
    .solutions-banner .btn.btn-primary {
      background: #fff;
      color: #007eff;
      border: transparent; }
      .solutions-banner .btn.btn-primary:hover {
        background: #fff; }
  .solutions-banner .link {
    display: flex;
    align-items: center;
    max-width: 200px; }
    .solutions-banner .link .icon-wrap {
      width: 60px;
      min-width: 60px;
      height: 60px;
      line-height: 55px;
      background: #fff;
      border-radius: 50%;
      text-align: center; }
      .solutions-banner .link .icon-wrap svg {
        width: 20px !important;
        height: 20px !important;
        margin-right: -7px;
        fill: #007eff; }
      .solutions-banner .link .icon-wrap + .link-text {
        padding-left: 10px; }
    .solutions-banner .link .link-text {
      font-size: 17px;
      font-weight: 600;
      text-transform: uppercase;
      color: #fff; }
  .solutions-banner .slider-img-wrap {
    margin: 0;
    text-align: center; }
