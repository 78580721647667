/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Tue Nov 09 2021
 * Category: Pages
 * Title: DevelopmentServices
 *
 */
.development-services {
  padding: 80px 0;
  overflow: hidden; }
  @media (max-width: 991px) {
    .development-services .main-heading.alter-main-heading .description {
      margin: 20px 0 0;
      max-width: 100%; } }
  .development-services .development-services-slider-item:focus {
    outline: none; }
  .development-services .slick-slide > div:focus {
    outline: none; }
  .development-services .slick-arrow {
    width: 50px;
    height: 50px;
    background: #fff;
    color: #202124;
    z-index: 2; }
    @media (max-width: 991px) {
      .development-services .slick-arrow {
        display: none !important; } }
    .development-services .slick-arrow:before {
      font-family: inherit; }
    .development-services .slick-arrow:before {
      color: #202124; }
    .development-services .slick-arrow.slick-prev {
      left: -60px; }
    .development-services .slick-arrow.slick-next {
      right: -60px; }
  .development-services .deve-card-wrap {
    margin: 20px; }
    .development-services .deve-card-wrap:hover .deve-card.card {
      background: #007eff;
      border-color: #007eff;
      transform: scale(1.03);
      box-shadow: 0 22px 25px 0 rgba(0, 126, 255, 0.21); }
      .development-services .deve-card-wrap:hover .deve-card.card .card-title {
        color: #fff; }
      .development-services .deve-card-wrap:hover .deve-card.card .card-text {
        color: #fff; }
      .development-services .deve-card-wrap:hover .deve-card.card .card-icon {
        fill: #fff !important;
        transform: translateX(20px); }
  .development-services .deve-card {
    min-height: 420px;
    cursor: pointer; }
    .development-services .deve-card.card {
      border-radius: 6px;
      transition: all ease-in-out .3s; }
      .development-services .deve-card.card .card-body {
        padding: 30px; }
      .development-services .deve-card.card .card-title {
        font-size: 30px;
        line-height: 1.3;
        margin-bottom: 20px;
        font-weight: 700;
        transition: all ease-in-out .3s; }
        @media (max-width: 1440px) {
          .development-services .deve-card.card .card-title {
            font-size: 26px; } }
      .development-services .deve-card.card .card-text {
        font-size: 18px;
        line-height: 1.7;
        font-weight: 500;
        margin-bottom: 20px;
        transition: all ease-in-out .3s; }
        @media (max-width: 1440px) {
          .development-services .deve-card.card .card-text {
            font-size: 16px; } }
      .development-services .deve-card.card .card-icon {
        fill: #96A5AB;
        transition: all ease-in-out .5s; }
    .development-services .deve-card.active.card {
      background: #007eff;
      border-color: #007eff;
      transform: scale(1.03); }
      .development-services .deve-card.active.card .card-title {
        color: #fff; }
      .development-services .deve-card.active.card .card-text {
        color: #fff; }
      .development-services .deve-card.active.card .card-icon {
        fill: #fff !important; }
