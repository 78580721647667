@font-face {
  font-display: swap;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  src: url(./assets/fonts/Gilroy-Regular-e25ee40195da6f33618ba7c03d2b66a4.woff2) format("woff2"), url(./assets/fonts/Gilroy-Regular-e0879529bc261e7247c6e4a4b6f670c7.woff) format("woff"); }

@font-face {
  font-display: swap;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 500;
  src: url(./assets/fonts/Gilroy-Medium-a64dce81db9e8889c8d38234f85cb1c7.woff2) format("woff2"), url(./assets/fonts/Gilroy-Medium-a46ff9656a37256d62e8a086e4b2f766.woff) format("woff"); }

@font-face {
  font-display: swap;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  src: url(./assets/fonts/Gilroy-SemiBold-12311b38a13f451c15040a7ce33652cd.woff2) format("woff2"), url(./assets/fonts/Gilroy-SemiBold-07559043612d8f26cd8b4e62c5de57ca.woff) format("woff"); }

@font-face {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 700;
  src: url(./assets/fonts/Gilroy-Bold-2ffee4e4db2cdfa9b7b4de5df3004172.woff2) format("woff2"), url(./assets/fonts/Gilroy-Bold-20091a5334cb2a0c9f97fa58bfbdcd22.woff) format("woff"); }

@font-face {
  font-display: swap;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 800;
  src: url(./assets/fonts/Gilroy-ExtraBold-83a93e62c6bda423ff7ac383e653bd9b.woff2) format("woff2"), url(./assets/fonts/Gilroy-ExtraBold-991e2eae044b7eb5e1b6ed1e6e521ed8.woff) format("woff"); }

@font-face {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 900;
  src: url(./assets/fonts/Gilroy-Black-fc99f47a5275763ee82407729a56b5a8.woff2) format("woff2"), url(./assets/fonts/Gilroy-Black-a7017ba231ab8dc770e0534ccd330e2a.woff) format("woff"); }

@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video-inner {
  from {
    transform: translate(0, 100px); }
  to {
    transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -ms-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    -moz-transform: translate(0, 100px);
    -ms-transform: translate(0, 100px);
    -o-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -moz-transition: -moz-transform 0.3s ease-out;
  -ms-transition: -ms-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    transform: rotate(45deg); }
  .modal-video-close-btn:after {
    transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  font-size: 14px;
  font-family: "Gilroy";
  line-height: 24px; }
  body .wrapper {
    overflow-x: hidden; }

* {
  box-sizing: border-box;
  font-family: inherit;
  -webkit-font-smoothing: antialiased;
  color: #202124; }

ul {
  margin: 0;
  padding: 0;
  list-style: none; }

a {
  color: #007eff;
  text-decoration: none;
  transition: all ease-in-out .4s; }

h1 {
  font-weight: 700;
  line-height: 1.24;
  font-size: 47px; }

h2 {
  font-size: 42px;
  font-weight: 700;
  line-height: 1.28; }

h3 {
  font-size: 37px;
  font-weight: 700;
  line-height: 1.34; }

h4 {
  font-size: 32px;
  font-weight: 700;
  line-height: 1.35; }

h5 {
  font-size: 27px;
  font-weight: 600;
  line-height: 1.35; }

h6 {
  font-size: 22px;
  font-weight: 400;
  line-height: 1.2; }

p {
  font-size: 18px;
  line-height: 1.7; }

img {
  max-width: 100%; }

.icon-wrap img {
  min-width: 50%;
  height: 50px; }

.scroller {
  overflow: scroll !important;
  -ms-overflow-style: none;
  scrollbar-width: none; }
  .scroller::-webkit-scrollbar {
    display: none; }

.hr-line {
  border-bottom: 1px solid #8ca0a8;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 20px; }

.row-holder {
  align-items: center; }

.row-justify-center {
  justify-content: center; }

.p-relative {
  position: relative; }

.p-absolute {
  position: absolute; }

.text-left {
  text-align: left; }

.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

/* Display None Helper Classes */
.d-none {
  display: none; }

.d-block {
  display: block; }

.d-inline-block {
  display: inline-block; }

/* Display None Helper Classes */
/** Theme Text Color **/
.text-white {
  color: #fff; }

.text-black {
  color: #202124; }

.text-normal {
  color: #96A5AB; }

.text-danger {
  color: #C50B0C; }

.text-primary {
  color: #007eff; }

.text-success {
  color: #34A853; }

.text-warning {
  color: #FBBC05; }

.text-secondary {
  color: #00cfff; }

.text-orange {
  color: #f77a4c; }

.text-purple {
  color: #a752e4; }

/** Theme Text Color **/
/** Theme Background Color **/
.bg-base {
  background: #F5F7F7; }

.bg-black {
  background: #202124; }

.bg-danger {
  background: #C50B0C; }

.bg-primary {
  background: #007eff; }

.bg-secondary {
  background: #00cfff; }

.bg-success {
  background: #34A853; }

.bg-warning {
  background: #FBBC05; }

/** /.Theme Background Color **/
/** Animation **/
.scroll-up-down {
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: scroll; }

@keyframes scroll {
  0% {
    transform: translateY(0px); }
  50% {
    transform: translateY(10px); }
  100% {
    transform: translateY(0px); } }

.scroll-down-up {
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-name: scroll; }

@keyframes scroll {
  0% {
    transform: translateY(0px); }
  50% {
    transform: translateY(-10px); }
  100% {
    transform: translateY(0px); } }

.rotate-360 {
  animation-duration: 7s;
  animation-iteration-count: infinite;
  animation-name: hourglass; }

@keyframes hourglass {
  0% {
    transform: rotate(0deg); }
  50% {
    transform: rotate(180deg); }
  100% {
    transform: rotate(0deg); } }

.heartbeat {
  animation: heartbeat 3s infinite alternate; }

@keyframes heartbeat {
  to {
    -webkit-transform: scale(1.1);
    transform: scale(1.1); } }

@-webkit-keyframes heartbeat {
  to {
    -webkit-transform: scale(1.1); } }

.rotate {
  -webkit-animation-name: rotateme;
  animation-name: rotateme;
  -webkit-animation-duration: 9s;
  animation-duration: 9s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear; }

@keyframes rotateme {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-webkit-keyframes rotateme {
  from {
    -webkit-transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg); } }

/** Animation End **/
/** Buttons **/
.btn.btn-primary {
  background-color: #007eff;
  border-color: #007eff; }
  .btn.btn-primary:hover {
    background-color: transparent;
    border-color: #007eff;
    color: #007eff; }

.btn.btn-warning {
  background-color: #FBBC05;
  border-color: #FBBC05;
  color: #fff; }
  .btn.btn-warning:hover {
    background-color: transparent;
    border-color: #FBBC05;
    color: #FBBC05; }

.btn.btn-secondary {
  background-color: #00cfff;
  border-color: #00cfff;
  color: #fff; }
  .btn.btn-secondary:hover {
    background-color: transparent;
    border-color: #00cfff;
    color: #00cfff; }

.btn.btn-outline-primary {
  color: #007eff;
  border-color: #007eff; }
  .btn.btn-outline-primary:hover {
    color: #fff;
    background-color: #007eff;
    border-color: #007eff; }

.btn.btn-outline-secondary {
  color: #00cfff;
  border-color: #00cfff; }
  .btn.btn-outline-secondary:hover {
    color: #fff;
    background-color: #00cfff;
    border-color: #00cfff; }

/** Buttons End **/
/** Tabs **/
.nav.nav-tabs {
  border: 0; }
  .nav.nav-tabs .nav-link {
    cursor: pointer;
    border: 0;
    padding: 25px 35px;
    font-size: 17px;
    font-weight: 600;
    color: #202124; }
    .nav.nav-tabs .nav-link.active {
      color: #007eff;
      border-bottom: 2px solid #007eff; }
    .nav.nav-tabs .nav-link:hover {
      color: #007eff; }
    @media (max-width: 991px) {
      .nav.nav-tabs .nav-link {
        font-size: 16px; } }
    @media (max-width: 767px) {
      .nav.nav-tabs .nav-link {
        font-size: 15px; } }
    @media (max-width: 480px) {
      .nav.nav-tabs .nav-link {
        font-size: 14px; } }

/** Tabs End **/
/** Links **/
.link-underline {
  color: #007eff;
  font-weight: 700;
  position: relative;
  transition: all ease-in-out .4s; }
  .link-underline:after {
    content: '';
    display: inline-block;
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: -5px;
    left: 0;
    background-color: #007eff;
    transform-origin: right center;
    transform: scaleX(0);
    transition: all ease-in-out .4s; }
  .link-underline:hover {
    color: #007eff; }
    .link-underline:hover:after {
      transform-origin: left center;
      transform: scaleX(1); }

/** Link End **/
/** Main Heading **/
.main-heading {
  text-align: center; }
  .main-heading .sub-title {
    color: #cfd6d8;
    display: inline-block;
    font-weight: 700;
    margin-bottom: 10px; }
  .main-heading .main-title {
    font-size: 42px;
    font-weight: 700;
    line-height: 1.2;
    margin: 0; }
    .main-heading .main-title + .description {
      margin-top: 20px; }
    @media (max-width: 1380px) {
      .main-heading .main-title {
        font-size: 37px; } }
    @media (max-width: 1110px) {
      .main-heading .main-title {
        font-size: 30px; } }
    @media (max-width: 991px) {
      .main-heading .main-title {
        font-size: 23px; } }
    @media (max-width: 767px) {
      .main-heading .main-title {
        font-size: 21px; } }
    @media (max-width: 400px) {
      .main-heading .main-title {
        font-size: 18px;
        line-height: 1.5; } }
  .main-heading .description {
    font-size: 20px;
    line-height: 1.6;
    margin-bottom: 0; }
    .main-heading .description:not(:last-child) {
      margin-bottom: 20px; }
    @media (max-width: 400px) {
      .main-heading .description {
        font-size: 16px; } }
  .main-heading.alter-main-heading {
    text-align: left;
    margin-bottom: 80px; }
    .main-heading.alter-main-heading .description {
      max-width: 80%;
      margin: 0 auto 0 30px; }
  .main-heading.main-heading-left {
    text-align: left;
    margin-bottom: 80px; }

/** Main Heading End **/
/** Count Box **/
.count-box {
  background: #fff;
  padding: 50px;
  text-align: center;
  margin-bottom: 40px; }
  .count-box .title {
    display: block;
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 20px;
    color: #007eff; }
  .count-box .sub-title {
    display: block;
    font-size: 22px; }

/** Count Box **/
/** Mobile App Development **/
.mobile-app-development .product-section .main-heading {
  margin-bottom: 30px;
  max-width: 100%; }

.mobile-app-development .product-section .row-holder {
  padding: 0; }

.mobile-app-development .deve-caption-wrap {
  padding: 80px 0; }
  .mobile-app-development .deve-caption-wrap .main-heading {
    text-align: left; }
    .mobile-app-development .deve-caption-wrap .main-heading .main-title {
      margin-bottom: 30px; }
    .mobile-app-development .deve-caption-wrap .main-heading .description {
      font-weight: 500;
      font-size: 20px;
      line-height: 1.6; }
      .mobile-app-development .deve-caption-wrap .main-heading .description:not(:last-child) {
        margin-bottom: 30px; }
      @media (max-width: 400px) {
        .mobile-app-development .deve-caption-wrap .main-heading .description {
          font-size: 16px; } }
  .mobile-app-development .deve-caption-wrap.deve-caption-primary {
    background: #007eff; }
    .mobile-app-development .deve-caption-wrap.deve-caption-primary .main-heading .main-title {
      color: #fff; }
    .mobile-app-development .deve-caption-wrap.deve-caption-primary .main-heading .description {
      color: #fff; }

.mobile-app-development .product-caption .link-underline {
  font-size: 16px;
  font-weight: 600; }

/** Mobile App Development End **/
/** Solutions Inner Page **/
.solutions-inner-page .solutions-tabs-section {
  padding: 80px 0; }
  .solutions-inner-page .solutions-tabs-section .main-heading {
    margin-bottom: 50px; }
  .solutions-inner-page .solutions-tabs-section .nav.nav-tabs {
    justify-content: center; }
    .solutions-inner-page .solutions-tabs-section .nav.nav-tabs .nav-link {
      padding: 25px 0;
      margin: 0 35px; }
  .solutions-inner-page .solutions-tabs-section .tab-content {
    margin-top: 40px; }
    .solutions-inner-page .solutions-tabs-section .tab-content .main-heading {
      margin: 0; }
      .solutions-inner-page .solutions-tabs-section .tab-content .main-heading .main-title {
        font-size: 35px; }

.solutions-inner-page .solutions-multi-widgets {
  padding-bottom: 80px; }
  .solutions-inner-page .solutions-multi-widgets .main-heading {
    margin-bottom: 80px; }
  .solutions-inner-page .solutions-multi-widgets .solutions-widget.card {
    text-align: center;
    margin-bottom: 30px;
    box-shadow: 0 4px 9px 0 rgba(23, 28, 33, 0.06); }
    .solutions-inner-page .solutions-multi-widgets .solutions-widget.card .card-body {
      padding: 50px; }
    .solutions-inner-page .solutions-multi-widgets .solutions-widget.card .icon-wrap {
      margin-bottom: 20px; }
    .solutions-inner-page .solutions-multi-widgets .solutions-widget.card .card-title {
      font-size: 24px;
      line-height: 1.3;
      margin-bottom: 15px;
      font-weight: 600; }
    .solutions-inner-page .solutions-multi-widgets .solutions-widget.card .card-text {
      font-size: 18px;
      line-height: 1.7;
      margin: 0;
      color: #202124;
      font-weight: 500; }

.solutions-inner-page .solutions-detail-widgets {
  padding: 80px 0; }
  .solutions-inner-page .solutions-detail-widgets .detail-widget-caption {
    margin-bottom: 50px; }
  .solutions-inner-page .solutions-detail-widgets .icon-wrap {
    margin-bottom: 20px; }
  .solutions-inner-page .solutions-detail-widgets .title {
    font-size: 42px;
    line-height: 1.2;
    margin-bottom: 15px;
    font-weight: 700; }
  .solutions-inner-page .solutions-detail-widgets .description {
    font-size: 18px;
    line-height: 1.7;
    margin: 0;
    color: #202124;
    font-weight: 500;
    padding-right: 60px; }

.solutions-inner-page .solutions-video-section {
  padding: 80px 0;
  background: #007eff;
  text-align: center; }
  .solutions-inner-page .solutions-video-section iframe {
    border: 15px solid #fff;
    border-radius: 14px;
    margin: 0; }
  .solutions-inner-page .solutions-video-section .main-heading {
    margin-bottom: 30px; }
    .solutions-inner-page .solutions-video-section .main-heading .main-title {
      color: #fff; }
    .solutions-inner-page .solutions-video-section .main-heading .description {
      color: #fff; }

/** Solutions Inner Page End **/
/** Work **/
.work .work-widget-section {
  padding: 80px 0; }
  .work .work-widget-section figure {
    margin: 0; }

.work .work-widget-caption {
  padding: 40px 0; }
  .work .work-widget-caption a {
    display: block; }
  .work .work-widget-caption .title {
    font-size: 42px;
    line-height: 1.2;
    margin-bottom: 15px;
    font-weight: 700;
    color: #fff; }
  .work .work-widget-caption .description {
    font-size: 20px;
    line-height: 1.7;
    margin-bottom: 30px;
    color: #202124;
    font-weight: 500;
    padding-right: 60px;
    color: #fff; }
  .work .work-widget-caption .btn {
    min-height: 57px;
    min-width: 200px;
    font-weight: 600;
    font-size: 17px;
    text-transform: uppercase;
    border-radius: 2px;
    margin-bottom: 30px; }
    .work .work-widget-caption .btn.btn-primary {
      background: #fff;
      color: #007eff;
      border: transparent; }
      .work .work-widget-caption .btn.btn-primary:hover {
        background: #fff; }

.work .load-more-wrap {
  padding: 80px 0 20px;
  background: #F5F7F7; }
  .work .load-more-wrap .hr-line {
    margin-top: 80px; }

/** Work End **/
/** About **/
.about .tech-leader {
  padding: 80px 0; }
  .about .tech-leader .img-wrap {
    margin-bottom: 30px; }
    .about .tech-leader .img-wrap img {
      box-shadow: 0 30px 100px rgba(0, 0, 0, 0.17);
      width: 550px;
      border-radius: 8px; }
  .about .tech-leader .tech-caption {
    padding-right: 110px; }
  .about .tech-leader .sub-title {
    font-size: 20px;
    font-weight: 500;
    color: #007eff;
    line-height: 1.5;
    margin-bottom: 20px; }
  .about .tech-leader .title {
    font-weight: 600;
    margin-bottom: 20px; }
  .about .tech-leader .description {
    font-weight: 500; }

.about .count-wrap {
  margin-bottom: 80px; }
  .about .count-wrap .hr-line {
    margin-bottom: 40px; }

.about .gredient-sec {
  background: linear-gradient(to right, #007eff 0, #a752e4 100%);
  padding: 100px 0 70px; }
  .about .gredient-sec .gredient-caption {
    padding-bottom: 30px; }
  .about .gredient-sec .title {
    color: #fff;
    margin-bottom: 20px; }
    .about .gredient-sec .title span {
      color: #fff; }
  .about .gredient-sec .description {
    color: #fff;
    font-weight: 500;
    font-size: 20px;
    margin: 0; }

.about .about-creative-sec {
  padding: 80px 0; }
  .about .about-creative-sec .main-heading {
    margin-bottom: 50px; }
    .about .about-creative-sec .main-heading .main-title + .description {
      margin-top: 10px; }
  .about .about-creative-sec .img-wrap {
    margin-bottom: 30px; }
    .about .about-creative-sec .img-wrap img {
      width: 100%;
      height: 580px;
      box-shadow: 0 30px 100px rgba(0, 0, 0, 0.17);
      border-radius: 8px; }
  .about .about-creative-sec h3 {
    font-size: 25px; }
  .about .about-creative-sec .text {
    font-weight: 500;
    padding-right: 70px; }

.about .media-mentions-sec {
  padding: 80px 0;
  background: #F5F7F7; }
  .about .media-mentions-sec .main-heading {
    margin-bottom: 50px; }
    .about .media-mentions-sec .main-heading .main-title + .description {
      margin-top: 10px; }
  .about .media-mentions-sec .media-card.card {
    text-align: center;
    cursor: pointer;
    border-radius: 5px;
    padding: 30px;
    border-color: transparent;
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    box-shadow: 0 4px 9px 0 rgba(23, 28, 33, 0.06); }
    .about .media-mentions-sec .media-card.card:hover, .about .media-mentions-sec .media-card.card:focus {
      animation-name: up, updown;
      animation-duration: .4s, 1.8s;
      animation-delay: 0s, .4s;
      animation-timing-function: ease-out, ease-in-out;
      animation-iteration-count: 1, infinite;
      animation-fill-mode: forwards;
      animation-direction: normal, alternate; }
    .about .media-mentions-sec .media-card.card .card-title {
      font-size: 20px;
      font-weight: 600;
      line-height: 1.3;
      margin-bottom: 0;
      min-height: 80px; }

@keyframes up {
  100% {
    transform: translateY(-8px); } }

@keyframes updown {
  0% {
    transform: translateY(-8px); }
  50% {
    transform: translateY(-4px); }
  100% {
    transform: translateY(-8px); } }

.about .tech-experts-sec {
  padding: 80px 0 50px;
  background: #F5F7F7; }
  .about .tech-experts-sec .main-heading {
    margin-bottom: 50px; }
    .about .tech-experts-sec .main-heading .main-title + .description {
      margin-top: 10px; }
  .about .tech-experts-sec .nav.nav-tabs {
    justify-content: center; }
    .about .tech-experts-sec .nav.nav-tabs .nav-link {
      background: transparent; }
  .about .tech-experts-sec .tab-content {
    margin-top: 30px; }
  .about .tech-experts-sec .tech-box {
    position: relative;
    transition: all ease-in-out .4s;
    cursor: pointer;
    margin-bottom: 30px; }
    .about .tech-experts-sec .tech-box:hover .tech-box-caption {
      opacity: 1;
      visibility: visible; }
    .about .tech-experts-sec .tech-box figure {
      margin-bottom: 0; }
    .about .tech-experts-sec .tech-box .tech-box-caption {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(24, 27, 49, 0.9);
      opacity: 0;
      visibility: hidden;
      transition: all ease-in-out .4s; }
    .about .tech-experts-sec .tech-box .box-name {
      color: #fff;
      font-size: 20px;
      margin: 0;
      line-height: 1.5; }
    .about .tech-experts-sec .tech-box .box-position {
      color: #fff;
      font-size: 12px;
      margin: 0; }

.about .client-sec {
  padding: 80px 0; }
  .about .client-sec .client-logo-wrap {
    text-align: center; }
    .about .client-sec .client-logo-wrap img {
      width: 18%; }

.about .office-video-sec {
  padding: 80px 0 0;
  background: #F5F7F7; }
  .about .office-video-sec .main-heading {
    margin-bottom: 50px; }
    .about .office-video-sec .main-heading .main-title + .description {
      margin-top: 10px; }
  .about .office-video-sec .video-items-wrap {
    padding-bottom: 70px;
    border-bottom: 1px solid #d4dcdf; }
  .about .office-video-sec .video-item {
    position: relative;
    cursor: pointer;
    margin-bottom: 30px;
    overflow: hidden;
    transition: all ease-in-out .4s; }
    .about .office-video-sec .video-item:hover {
      box-shadow: 0 50px 75px rgba(0, 0, 0, 0.2); }
      .about .office-video-sec .video-item:hover .img-wrap {
        transform: scale(1.08); }
      .about .office-video-sec .video-item:hover .icon-wrap .icon {
        transform: translateY(-10px) scale(1.2); }
    .about .office-video-sec .video-item .img-wrap {
      transition: all linear .4s;
      margin: 0; }
    .about .office-video-sec .video-item .icon-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.2); }
      .about .office-video-sec .video-item .icon-wrap .icon {
        width: 55px;
        height: 55px;
        line-height: 48px;
        text-align: center;
        background: #007eff;
        border-radius: 50%;
        transition: all linear .5s; }
        .about .office-video-sec .video-item .icon-wrap .icon svg {
          fill: #fff; }

.about .work-banner {
  background: url("https://www.cubix.co/themes/responsiv-clean/assets/images/testimonials/cubix-clients1.jpg") no-repeat;
  background-position: center center;
  background-size: cover; }
  .about .work-banner .main-heading .main-title {
    color: #fff; }
    .about .work-banner .main-heading .main-title + .description {
      margin-top: 20px; }
  .about .work-banner .main-heading .description {
    color: #fff; }

/** About End **/
/** About Process **/
.about-process .process-detail-sec {
  padding: 80px 0; }
  .about-process .process-detail-sec .main-heading {
    margin-bottom: 50px; }
  .about-process .process-detail-sec .coonect-wrap {
    background: url("https://www.cubix.co/themes/responsiv-clean/assets/images/process/connect-c-c.svg") no-repeat;
    background-position: center;
    background-size: auto;
    height: 70px; }
  .about-process .process-detail-sec .process-caption {
    padding: 50px 0; }
    .about-process .process-detail-sec .process-caption .title {
      text-align: right; }
    .about-process .process-detail-sec .process-caption .description {
      font-size: 20px;
      font-weight: 500;
      padding-right: 120px; }
  .about-process .process-detail-sec .icon-wrap {
    text-align: center; }
    .about-process .process-detail-sec .icon-wrap .enlivenem {
      width: 128px; }
      .about-process .process-detail-sec .icon-wrap .enlivenem .cls-1 {
        fill: none;
        stroke: #e22f2c;
        stroke-miterlimit: 10;
        stroke-width: 1.5px; }

/** About Process **/
/** About Clients **/
.about-clients .client-sec {
  padding: 80px 0 50px; }
  .about-clients .client-sec .row .col-12 {
    padding-bottom: 30px; }

/** About Clients End **/
/** About Testimonials **/
.about-testimonials .client-testimonials-video {
  padding: 80px 0 10px; }
  .about-testimonials .client-testimonials-video .video-wrap {
    width: 100%;
    height: 315px; }

.about-testimonials .client-comments-sec {
  padding: 70px 0 50px; }

.about-testimonials .client-comments-item {
  margin-bottom: 30px; }
  .about-testimonials .client-comments-item .message {
    background: #F5F7F7;
    padding: 40px;
    position: relative; }
    .about-testimonials .client-comments-item .message::before {
      content: '';
      position: absolute;
      border: 10px solid transparent;
      border-top-color: #F5F7F7;
      top: 100%;
      left: 40px; }
  .about-testimonials .client-comments-item .author-detail {
    display: flex;
    align-items: center;
    padding: 10px 25px 0; }
    .about-testimonials .client-comments-item .author-detail .avatar {
      display: inline-block;
      min-width: 50px;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      overflow: hidden; }
      .about-testimonials .client-comments-item .author-detail .avatar + .author-info {
        margin-left: 10px; }
    .about-testimonials .client-comments-item .author-detail .author-info {
      flex: 1 1 100%; }
      .about-testimonials .client-comments-item .author-detail .author-info .title {
        display: block;
        font-size: 17px;
        font-weight: 600;
        margin-bottom: 5px; }
      .about-testimonials .client-comments-item .author-detail .author-info .location {
        display: block;
        color: #96A5AB;
        font-weight: 500; }

/** About Testimonials End **/
/** Contact **/
.contact {
  overflow: hidden;
  height: 100vh; }
  @media (max-width: 991px) {
    .contact {
      height: 100%; } }
  .contact .container-fluid {
    padding: 0; }
  .contact .logo {
    margin-bottom: 20px;
    display: inline-block; }
  .contact .contact-left {
    height: 100vh;
    padding: 20px;
    background: linear-gradient(to bottom, #012690 0, #1cafc6 100%);
    position: relative; }
    @media (max-width: 991px) {
      .contact .contact-left {
        height: 335px;
        padding: 40px 20px; } }
  .contact .contact-left-content {
    text-align: center; }
    .contact .contact-left-content .title {
      color: #fff;
      font-size: 25px;
      margin-bottom: 10px; }
    .contact .contact-left-content p {
      color: #fff;
      font-weight: 500;
      margin-bottom: 5px;
      font-size: 20px; }
      .contact .contact-left-content p span {
        color: #fff;
        font-weight: 500; }
    .contact .contact-left-content .link-underline {
      font-size: 16px;
      color: #fff;
      font-weight: 500;
      text-transform: uppercase; }
      .contact .contact-left-content .link-underline::after {
        background: #fff; }
      .contact .contact-left-content .link-underline::before {
        content: '';
        display: inline-block;
        width: 100%;
        height: 1px;
        position: absolute;
        bottom: -5px;
        left: 0;
        background-color: rgba(255, 255, 255, 0.4); }
      .contact .contact-left-content .link-underline:hover {
        color: #007eff; }
    .contact .contact-left-content .img-wrap {
      margin: 40px 0 0;
      text-align: center; }
      .contact .contact-left-content .img-wrap img {
        margin: 0 auto; }
      @media (max-width: 991px) {
        .contact .contact-left-content .img-wrap {
          display: none; } }
    .contact .contact-left-content .birds-img {
      position: absolute;
      top: 240px;
      left: -10px; }
  .contact .contact-right {
    padding: 20px;
    height: 100vh; }
    @media (max-width: 991px) {
      .contact .contact-right {
        height: 100%;
        padding: 40px 20px; } }
    .contact .contact-right .contact-list {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      margin-right: 80px;
      margin-bottom: 80px; }
      @media (max-width: 767px) {
        .contact .contact-right .contact-list {
          margin: 0 0 40px;
          align-items: center; } }
      .contact .contact-right .contact-list li {
        margin-bottom: 10px; }
      .contact .contact-right .contact-list a {
        display: flex;
        align-items: center;
        font-size: 17px;
        font-weight: 600; }
      .contact .contact-right .contact-list svg {
        width: 15px;
        height: 15px; }
        .contact .contact-right .contact-list svg + .link-text {
          margin-left: 10px; }
    .contact .contact-right .main-heading {
      margin-bottom: 30px; }
      .contact .contact-right .main-heading .main-title + .description {
        margin-top: 10px; }
    .contact .contact-right .form-inline {
      max-width: 62%;
      margin: 0 auto; }
      @media (max-width: 767px) {
        .contact .contact-right .form-inline {
          max-width: 80%; } }
      @media (max-width: 567px) {
        .contact .contact-right .form-inline {
          max-width: 100%; } }
      .contact .contact-right .form-inline .form-group {
        margin-bottom: 10px; }
      .contact .contact-right .form-inline label {
        font-size: 15px;
        font-weight: 500;
        color: #96A5AB;
        margin-bottom: 10px; }
      .contact .contact-right .form-inline .form-control {
        border-bottom-width: 2px;
        box-shadow: none;
        resize: none; }
      .contact .contact-right .form-inline textarea.form-control {
        min-height: 100px;
        margin-bottom: 20px; }
    .contact .contact-right .btn {
      width: 100%; }

/** Contact **/
/** Masonry **/
.my-masonry-grid {
  display: -webkit-box;
  /* Not needed if autoprefixing */
  display: -ms-flexbox;
  /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px;
  /* gutter size offset */
  width: auto; }

.my-masonry-grid_column {
  padding-left: 30px;
  /* gutter size */
  background-clip: padding-box; }

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 30px; }

/** Masonry End **/
/** Privacy Policy **/
.privacy-policy article {
  padding: 50px 0; }

/** Privacy Policy **/
/** Careers **/
.careers-page section {
  padding: 100px 0; }
  @media (max-width: 991px) {
    .careers-page section {
      padding: 60px 0; } }
  .careers-page section:nth-child(even) {
    background: #F9FAFA; }
  .careers-page section .title {
    margin-bottom: 20px;
    font-weight: 500; }
    @media (max-width: 1200px) {
      .careers-page section .title {
        font-size: 30px; } }
    @media (max-width: 767px) {
      .careers-page section .title {
        font-size: 26px; } }
  .careers-page section p {
    margin: 0; }
    .careers-page section p:not(:last-child) {
      margin-bottom: 30px; }

.careers-page .careers-banner {
  padding: 100px 0;
  min-height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35));
  background-size: cover; }
  @media (max-width: 991px) {
    .careers-page .careers-banner {
      padding: 60px 0;
      min-height: 400px; } }
  @media (max-width: 567px) {
    .careers-page .careers-banner {
      min-height: 300px; } }
  .careers-page .careers-banner .careers-banner-caption {
    position: relative;
    z-index: 2;
    text-align: center; }
    .careers-page .careers-banner .careers-banner-caption .title {
      font-size: 36px;
      margin-bottom: 20px;
      font-weight: 500;
      color: #96A5AB; }
      @media (max-width: 991px) {
        .careers-page .careers-banner .careers-banner-caption .title {
          font-size: 32px; } }
      @media (max-width: 767px) {
        .careers-page .careers-banner .careers-banner-caption .title {
          font-size: 28px; } }
    .careers-page .careers-banner .careers-banner-caption .sub-title {
      font-size: 40px;
      margin-bottom: 0px;
      font-weight: 700;
      color: #fff; }
      @media (max-width: 991px) {
        .careers-page .careers-banner .careers-banner-caption .sub-title {
          font-size: 36px; } }
      @media (max-width: 767px) {
        .careers-page .careers-banner .careers-banner-caption .sub-title {
          font-size: 32px; } }

.careers-page figure img {
  width: 100%;
  text-align: center; }

.careers-page .careers-about-sec {
  position: relative; }
  .careers-page .careers-about-sec .icon {
    position: absolute;
    top: 40px;
    right: 50px; }
    @media (max-width: 1200px) {
      .careers-page .careers-about-sec .icon {
        width: 100px;
        height: 100px; } }
  .careers-page .careers-about-sec .careers-about-caption {
    position: relative;
    z-index: 2; }

.careers-page .story-sec {
  position: relative; }
  .careers-page .story-sec .icon {
    position: absolute;
    top: 40px;
    left: 50px; }
    @media (max-width: 1200px) {
      .careers-page .story-sec .icon {
        width: 100px;
        height: 100px; } }
  .careers-page .story-sec .story-caption {
    position: relative;
    z-index: 2; }
  .careers-page .story-sec .story-list {
    margin: 0 0 15px auto;
    max-width: 80%; }
    @media (max-width: 1200px) {
      .careers-page .story-sec .story-list {
        max-width: 90%; } }
    @media (max-width: 991px) {
      .careers-page .story-sec .story-list {
        max-width: 65%;
        margin-top: 40px; } }
    @media (max-width: 767px) {
      .careers-page .story-sec .story-list {
        max-width: 100%; } }
    .careers-page .story-sec .story-list li {
      display: inline-block;
      padding: 15px 30px;
      border-radius: 300px;
      background-color: #20E0AB;
      background-position: 25px 15px;
      background-size: auto;
      background-repeat: no-repeat;
      font-size: 18px;
      font-weight: 700; }
      @media (max-width: 1200px) {
        .careers-page .story-sec .story-list li {
          font-size: 16px; } }
      @media (max-width: 767px) {
        .careers-page .story-sec .story-list li {
          margin: 0 0 20px !important;
          display: block; } }
      .careers-page .story-sec .story-list li:not(:last-child) {
        margin-bottom: 20px; }
      .careers-page .story-sec .story-list li:nth-child(2) {
        margin-left: 60px; }
      .careers-page .story-sec .story-list li:nth-child(3) {
        margin-left: 120px; }
      .careers-page .story-sec .story-list li:nth-child(4) {
        margin-left: 180px; }
      .careers-page .story-sec .story-list li:nth-child(5) {
        margin-left: 120px; }
      .careers-page .story-sec .story-list li:nth-child(6) {
        margin-left: 60px; }
      .careers-page .story-sec .story-list li:nth-child(7) {
        margin-left: 0; }
      .careers-page .story-sec .story-list li span {
        color: #fff;
        display: flex;
        align-items: center; }
      .careers-page .story-sec .story-list li svg {
        width: 18px;
        height: 18px;
        margin-right: 15px; }

.careers-page .join-sec {
  text-align: center; }
  .careers-page .join-sec .title {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 30px; }
    @media (max-width: 991px) {
      .careers-page .join-sec .title {
        font-size: 24px; } }
  .careers-page .join-sec .btn {
    border-radius: 6px;
    font-weight: 500; }

.careers-page.careers-listing-page .careers-job-list-wrap {
  padding: 100px 0; }
  @media (max-width: 991px) {
    .careers-page.careers-listing-page .careers-job-list-wrap {
      padding: 60px 0; } }

.careers-page.careers-listing-page .job-list-content {
  margin-bottom: 60px; }
  .careers-page.careers-listing-page .job-list-content h2 {
    font-size: 28px;
    font-weight: 500;
    color: #202124;
    margin-bottom: 20px; }
    @media (max-width: 991px) {
      .careers-page.careers-listing-page .job-list-content h2 {
        font-size: 24px; } }
  .careers-page.careers-listing-page .job-list-content .job-list a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-flow: wrap row;
    gap: 10px 10px;
    margin-bottom: 5px;
    background: #F9FAFA;
    padding: 7px 20px;
    font-size: 16px;
    font-weight: 500;
    border-left: 1px solid #34A853;
    transition: all ease .3s; }
    .careers-page.careers-listing-page .job-list-content .job-list a span {
      display: inline-block;
      transition: all ease-in-out .3s; }
    .careers-page.careers-listing-page .job-list-content .job-list a:hover {
      border-left-width: 5px; }
      .careers-page.careers-listing-page .job-list-content .job-list a:hover span:first-child {
        color: #007eff; }

.careers-page.careers-listing-page .social-box {
  border: 1px solid #E3E8EA;
  padding: 15px 20px;
  border-radius: 6px; }
  .careers-page.careers-listing-page .social-box .title {
    font-size: 18px;
    color: #202124;
    font-weight: 500;
    margin-bottom: 10px; }
  .careers-page.careers-listing-page .social-box .social-list {
    display: flex;
    flex-flow: wrap row;
    gap: 10px 10px; }
    .careers-page.careers-listing-page .social-box .social-list a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background: #F5F7F7; }
      .careers-page.careers-listing-page .social-box .social-list a svg {
        width: 15px;
        height: 15px;
        fill: #fff; }
      .careers-page.careers-listing-page .social-box .social-list a:hover {
        transform: scale(1.11); }
    .careers-page.careers-listing-page .social-box .social-list li:nth-child(1) a {
      background: #0077b5; }
    .careers-page.careers-listing-page .social-box .social-list li:nth-child(2) a {
      background: #0062E0; }
    .careers-page.careers-listing-page .social-box .social-list li:nth-child(3) a {
      background: #3f729b; }
    .careers-page.careers-listing-page .social-box .social-list li:nth-child(4) a {
      background: #00acee; }

/** Careers End **/
/** Landing Linkedcamp Page **/
.landing-linkedcamp-page .landing-banner {
  padding: 270px 0 80px;
  border: 1px solid #E3E8EA;
  overflow: hidden; }
  @media (max-width: 991px) {
    .landing-linkedcamp-page .landing-banner {
      padding: 170px 0 80px; } }
  .landing-linkedcamp-page .landing-banner .landing-video-wrap {
    position: relative;
    text-align: right; }
    @media (max-width: 991px) {
      .landing-linkedcamp-page .landing-banner .landing-video-wrap {
        text-align: center; } }
    .landing-linkedcamp-page .landing-banner .landing-video-wrap::before {
      content: '';
      position: absolute;
      top: -130px;
      right: -9vw;
      background: url("./assets/images/banner-bg.png") no-repeat;
      background-size: cover;
      width: 439px;
      height: 490px; }
    .landing-linkedcamp-page .landing-banner .landing-video-wrap .icon {
      position: absolute; }
    .landing-linkedcamp-page .landing-banner .landing-video-wrap .coil-icon {
      top: -100px;
      left: 50px; }
    .landing-linkedcamp-page .landing-banner .landing-video-wrap .bar-1-icon {
      bottom: 20px;
      left: 65px; }
      @media (max-width: 991px) {
        .landing-linkedcamp-page .landing-banner .landing-video-wrap .bar-1-icon {
          left: 28px; } }
    .landing-linkedcamp-page .landing-banner .landing-video-wrap .bar-icon {
      top: -20px;
      left: 110px; }
    .landing-linkedcamp-page .landing-banner .landing-video-wrap .ploygon-1-icon {
      top: 20px;
      left: 92px; }
      @media (max-width: 991px) {
        .landing-linkedcamp-page .landing-banner .landing-video-wrap .ploygon-1-icon {
          left: 55px; } }
      @media (max-width: 567px) {
        .landing-linkedcamp-page .landing-banner .landing-video-wrap .ploygon-1-icon {
          left: 0; } }
    .landing-linkedcamp-page .landing-banner .landing-video-wrap .dot-icon {
      bottom: 40px;
      left: 0; }
    .landing-linkedcamp-page .landing-banner .landing-video-wrap .ploygon-icon {
      bottom: 40px;
      right: 10px; }
      @media (max-width: 567px) {
        .landing-linkedcamp-page .landing-banner .landing-video-wrap .ploygon-icon {
          right: 0; } }
    .landing-linkedcamp-page .landing-banner .landing-video-wrap .box-icon {
      top: -20px;
      right: 0; }
      @media (max-width: 991px) {
        .landing-linkedcamp-page .landing-banner .landing-video-wrap .box-icon {
          right: 40px; } }
      @media (max-width: 567px) {
        .landing-linkedcamp-page .landing-banner .landing-video-wrap .box-icon {
          right: -20px; } }
    .landing-linkedcamp-page .landing-banner .landing-video-wrap .dot-1-icon {
      top: -40px;
      right: 0; }
    .landing-linkedcamp-page .landing-banner .landing-video-wrap iframe {
      max-width: 80%;
      margin: 0 20px; }
      @media (max-width: 567px) {
        .landing-linkedcamp-page .landing-banner .landing-video-wrap iframe {
          max-width: 100%;
          margin: 0; } }
  .landing-linkedcamp-page .landing-banner .landing-banner-caption {
    padding-right: 100px; }
    @media (max-width: 1100px) {
      .landing-linkedcamp-page .landing-banner .landing-banner-caption {
        padding-right: 30px; } }
    @media (max-width: 991px) {
      .landing-linkedcamp-page .landing-banner .landing-banner-caption {
        padding-right: 0;
        padding-bottom: 170px; } }
    .landing-linkedcamp-page .landing-banner .landing-banner-caption .main-title {
      font-size: 51px;
      margin-bottom: 20px; }
      @media (max-width: 991px) {
        .landing-linkedcamp-page .landing-banner .landing-banner-caption .main-title {
          font-size: 41px; } }
      @media (max-width: 767px) {
        .landing-linkedcamp-page .landing-banner .landing-banner-caption .main-title {
          font-size: 35px; } }
      @media (max-width: 567px) {
        .landing-linkedcamp-page .landing-banner .landing-banner-caption .main-title {
          font-size: 32px; } }
    .landing-linkedcamp-page .landing-banner .landing-banner-caption p {
      font-size: 16px;
      margin-bottom: 30px;
      color: #202124; }
    .landing-linkedcamp-page .landing-banner .landing-banner-caption .btn {
      min-width: 150px;
      height: 57px;
      font-weight: 600;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px 10px; }
      .landing-linkedcamp-page .landing-banner .landing-banner-caption .btn img {
        width: 35px; }

.landing-linkedcamp-page .landing-sec {
  padding: 120px 0; }
  .landing-linkedcamp-page .landing-sec + .landing-sec {
    padding-top: 0; }
  @media (max-width: 991px) {
    .landing-linkedcamp-page .landing-sec .row {
      gap: 80px 0; } }
  @media (max-width: 767px) {
    .landing-linkedcamp-page .landing-sec {
      padding: 80px 0; } }
  .landing-linkedcamp-page .landing-sec .img-wrap {
    margin: 0; }
    .landing-linkedcamp-page .landing-sec .img-wrap img {
      width: 100%; }
  .landing-linkedcamp-page .landing-sec .landing-sec-caption {
    margin: 0 50px; }
    @media (max-width: 1100px) {
      .landing-linkedcamp-page .landing-sec .landing-sec-caption {
        margin: 0 20px; } }
    @media (max-width: 991px) {
      .landing-linkedcamp-page .landing-sec .landing-sec-caption {
        margin: 0; } }
    .landing-linkedcamp-page .landing-sec .landing-sec-caption .title {
      font-size: 40px;
      margin-bottom: 20px; }
      @media (max-width: 991px) {
        .landing-linkedcamp-page .landing-sec .landing-sec-caption .title {
          font-size: 35px; } }
      @media (max-width: 767px) {
        .landing-linkedcamp-page .landing-sec .landing-sec-caption .title {
          font-size: 30px; } }
      @media (max-width: 567px) {
        .landing-linkedcamp-page .landing-sec .landing-sec-caption .title {
          font-size: 26px; } }
    .landing-linkedcamp-page .landing-sec .landing-sec-caption p {
      color: #202124;
      font-size: 16px;
      margin: 0; }
      .landing-linkedcamp-page .landing-sec .landing-sec-caption p + p {
        margin-top: 30px; }

.landing-linkedcamp-page .main-img-sec .main-img {
  margin: 0; }
  .landing-linkedcamp-page .main-img-sec .main-img img {
    width: 100%; }

.landing-linkedcamp-page .landing-testimonials-sec {
  overflow: hidden;
  padding: 50px 0 80px;
  background: rgba(1, 147, 254, 0.3);
  position: relative; }
  .landing-linkedcamp-page .landing-testimonials-sec::before {
    content: "";
    position: absolute;
    top: 260px;
    left: -130px;
    background: rgba(0, 210, 255, 0.3);
    width: 120vw;
    height: 100%;
    transform: rotate(162deg); }
  .landing-linkedcamp-page .landing-testimonials-sec .main-heading {
    padding-bottom: 50px; }
    .landing-linkedcamp-page .landing-testimonials-sec .main-heading .main-title {
      font-size: 40px; }
      @media (max-width: 991px) {
        .landing-linkedcamp-page .landing-testimonials-sec .main-heading .main-title {
          font-size: 35px; } }
      @media (max-width: 767px) {
        .landing-linkedcamp-page .landing-testimonials-sec .main-heading .main-title {
          font-size: 30px; } }
      @media (max-width: 567px) {
        .landing-linkedcamp-page .landing-testimonials-sec .main-heading .main-title {
          font-size: 26px; } }
    .landing-linkedcamp-page .landing-testimonials-sec .main-heading .description {
      font-size: 16px;
      font-weight: 600;
      color: #202124; }
  .landing-linkedcamp-page .landing-testimonials-sec .landing-slider .landing-slider-card {
    margin: 20px;
    background: #fff;
    padding: 30px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0 44px 0 0;
    display: flex;
    align-items: center;
    gap: 20px 20px; }
    @media (max-width: 567px) {
      .landing-linkedcamp-page .landing-testimonials-sec .landing-slider .landing-slider-card {
        flex-direction: column; } }
    .landing-linkedcamp-page .landing-testimonials-sec .landing-slider .landing-slider-card .onwer-pic {
      position: relative;
      min-width: 110px; }
      .landing-linkedcamp-page .landing-testimonials-sec .landing-slider .landing-slider-card .onwer-pic::before {
        content: "";
        position: absolute;
        bottom: -23px;
        left: -20px;
        width: 42px;
        height: 42px;
        background: url("./assets/images/testimonials-bg.png") no-repeat;
        background-size: cover; }
    .landing-linkedcamp-page .landing-testimonials-sec .landing-slider .landing-slider-card .caption .title {
      font-size: 16px;
      margin-bottom: 0; }
    .landing-linkedcamp-page .landing-testimonials-sec .landing-slider .landing-slider-card .caption .designation {
      font-size: 12px;
      display: block;
      margin-bottom: 10px; }
    .landing-linkedcamp-page .landing-testimonials-sec .landing-slider .landing-slider-card .caption blockquote {
      font-size: 12px;
      line-height: 19px;
      color: #202124;
      position: relative; }
      .landing-linkedcamp-page .landing-testimonials-sec .landing-slider .landing-slider-card .caption blockquote::before {
        content: "";
        position: absolute;
        top: -30px;
        right: 15px;
        width: 17px;
        height: 17px;
        background: url("./assets/images/quote.png") no-repeat;
        background-size: contain; }
  .landing-linkedcamp-page .landing-testimonials-sec .landing-slider .slick-dots {
    bottom: -40px;
    z-index: 2; }
    .landing-linkedcamp-page .landing-testimonials-sec .landing-slider .slick-dots li {
      width: 16px;
      height: 16px;
      transition: all ease-in-out .4s; }
      .landing-linkedcamp-page .landing-testimonials-sec .landing-slider .slick-dots li button {
        background: #fff;
        padding: 0;
        width: 16px;
        height: 16px;
        border-radius: 19px;
        transition: all ease-in-out .4s; }
        .landing-linkedcamp-page .landing-testimonials-sec .landing-slider .slick-dots li button:before {
          display: none; }
      .landing-linkedcamp-page .landing-testimonials-sec .landing-slider .slick-dots li.slick-active {
        width: 20px;
        height: 20px; }
        .landing-linkedcamp-page .landing-testimonials-sec .landing-slider .slick-dots li.slick-active button {
          width: 20px;
          height: 20px;
          background: #007eff; }

.landing-linkedcamp-page .blog-sec {
  padding: 80px 0 40px; }
  .landing-linkedcamp-page .blog-sec .blog-card {
    margin-bottom: 40px;
    cursor: pointer; }
    .landing-linkedcamp-page .blog-sec .blog-card:hover .title {
      color: #007eff; }
    .landing-linkedcamp-page .blog-sec .blog-card .blog-img {
      margin-bottom: 30px; }
      .landing-linkedcamp-page .blog-sec .blog-card .blog-img img {
        width: 100%; }
    .landing-linkedcamp-page .blog-sec .blog-card .title {
      font-size: 18px;
      margin-bottom: 10px;
      transition: all ease-in-out .4s; }
    .landing-linkedcamp-page .blog-sec .blog-card p {
      font-size: 14px;
      margin-bottom: 0;
      color: #202124; }

.landing-linkedcamp-page .ready-sec {
  background: url("./assets/images/ready-bg.png") no-repeat;
  background-size: cover;
  padding: 80px 0; }
  @media (max-width: 767px) {
    .landing-linkedcamp-page .ready-sec {
      background-position: center right; } }
  .landing-linkedcamp-page .ready-sec .main-heading .main-title {
    font-size: 50px;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 15px; }
    @media (max-width: 991px) {
      .landing-linkedcamp-page .ready-sec .main-heading .main-title {
        font-size: 40px; } }
    @media (max-width: 767px) {
      .landing-linkedcamp-page .ready-sec .main-heading .main-title {
        font-size: 34px; } }
    @media (max-width: 567px) {
      .landing-linkedcamp-page .ready-sec .main-heading .main-title {
        font-size: 30px; } }
  .landing-linkedcamp-page .ready-sec .main-heading .sub-title {
    font-size: 27px;
    color: #202124;
    font-weight: 500; }
    @media (max-width: 991px) {
      .landing-linkedcamp-page .ready-sec .main-heading .sub-title {
        font-size: 23px; } }
    @media (max-width: 767px) {
      .landing-linkedcamp-page .ready-sec .main-heading .sub-title {
        font-size: 21px; } }
    @media (max-width: 567px) {
      .landing-linkedcamp-page .ready-sec .main-heading .sub-title {
        font-size: 19px; } }
  .landing-linkedcamp-page .ready-sec .main-heading .actions-wrap {
    padding: 30px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px 20px; }
    @media (max-width: 767px) {
      .landing-linkedcamp-page .ready-sec .main-heading .actions-wrap {
        flex-direction: column; } }
    .landing-linkedcamp-page .ready-sec .main-heading .actions-wrap .btn {
      min-width: 331px;
      padding: 0 20px;
      height: 87px;
      font-size: 27px;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px 20px; }
      @media (max-width: 991px) {
        .landing-linkedcamp-page .ready-sec .main-heading .actions-wrap .btn {
          font-size: 23px; } }
      @media (max-width: 767px) {
        .landing-linkedcamp-page .ready-sec .main-heading .actions-wrap .btn {
          font-size: 21px; } }
      @media (max-width: 567px) {
        .landing-linkedcamp-page .ready-sec .main-heading .actions-wrap .btn {
          font-size: 19px;
          min-width: 100%; } }
      .landing-linkedcamp-page .ready-sec .main-heading .actions-wrap .btn.black {
        background: #000;
        border-color: #000; }
        .landing-linkedcamp-page .ready-sec .main-heading .actions-wrap .btn.black:hover {
          color: #000;
          background: transparent;
          border-color: #000; }
  .landing-linkedcamp-page .ready-sec .main-heading p {
    font-size: 14px;
    font-weight: 700; }
    .landing-linkedcamp-page .ready-sec .main-heading p:last-child {
      margin: 0; }
    .landing-linkedcamp-page .ready-sec .main-heading p strong {
      font-weight: 700;
      font-size: 24px; }
      @media (max-width: 991px) {
        .landing-linkedcamp-page .ready-sec .main-heading p strong {
          font-size: 20px; } }
      @media (max-width: 767px) {
        .landing-linkedcamp-page .ready-sec .main-heading p strong {
          font-size: 18px; } }

.landing-linkedcamp-page .landing-footer {
  background: #000;
  padding: 30px 0; }
  .landing-linkedcamp-page .landing-footer .landing-footer-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px 20px; }
    @media (max-width: 991px) {
      .landing-linkedcamp-page .landing-footer .landing-footer-inner {
        flex-direction: column-reverse;
        text-align: center; } }
  .landing-linkedcamp-page .landing-footer .landing-footer-menu {
    display: flex;
    align-items: center;
    gap: 20px 20px; }
    @media (max-width: 567px) {
      .landing-linkedcamp-page .landing-footer .landing-footer-menu {
        justify-content: center;
        flex-flow: wrap row; } }
    .landing-linkedcamp-page .landing-footer .landing-footer-menu a {
      color: #fff;
      font-size: 16px;
      transition: all ease-in-out .4s; }
      .landing-linkedcamp-page .landing-footer .landing-footer-menu a:hover {
        opacity: .7; }
  .landing-linkedcamp-page .landing-footer p {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    margin: 0; }

/** Landing Linkedcamp Page End **/
