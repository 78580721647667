/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Thu Nov 11 2021
 * Category: Pages
 * Title: ExploreSection
 *
 */
.explore-section {
  padding: 50px 0 80px;
  background: #F5F7F7; }
  .explore-section .img-wrap {
    margin-bottom: 30px; }
