/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Mon Nov 15 2021
 * Category: Pages
 * Title: WorkBanner
 *
 */
.work-banner {
  min-height: 75vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35));
  background-size: cover; }
  .work-banner .main-heading {
    width: 100%; }
    .work-banner .main-heading .main-title {
      color: #fff; }
      .work-banner .main-heading .main-title + .description {
        margin-top: 0; }
    .work-banner .main-heading .description {
      color: #fff; }
      .work-banner .main-heading .description span {
        color: #fff; }
  .work-banner .icon-wrap {
    display: block;
    position: absolute;
    bottom: 80px;
    left: 0;
    right: 0;
    width: 30px;
    margin: 0 auto;
    cursor: pointer; }
