/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Wed Oct 06 2021
 * Category: Pages
 * Title: InnerBanner
 *
 */
.inner-banner {
  position: relative;
  padding: 140px 0 70px;
  overflow: hidden; }
  .inner-banner:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: url("https://mobylogix.s3.amazonaws.com/v4/header.png") rgba(59, 48, 44, 0.8) no-repeat;
    background-size: contain;
    background-position: top right; }
  .inner-banner .inner-banner-caption {
    position: relative;
    z-index: 2;
    padding-top: 60px;
    padding-right: 90px; }
    @media (max-width: 767px) {
      .inner-banner .inner-banner-caption {
        padding: 0; } }
  .inner-banner .title {
    font-size: 47px;
    font-weight: 700;
    line-height: 1.25;
    color: #fff; }
    .inner-banner .title span {
      color: #fff; }
    @media (max-width: 991px) {
      .inner-banner .title {
        font-size: 42px; } }
    @media (max-width: 767px) {
      .inner-banner .title {
        font-size: 37px; } }
  .inner-banner .description {
    font-size: 21px;
    font-weight: 500;
    color: #fff;
    line-height: 1.5;
    margin: 25px 0 45px; }
    .inner-banner .description span {
      color: #fff; }
  .inner-banner .slider-img-wrap {
    margin: 0 10px;
    text-align: center;
    line-height: 37px; }
    .inner-banner .slider-img-wrap img {
      width: 100%;
      display: inline-block;
      max-width: 120px; }
