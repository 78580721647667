/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Wed Nov 10 2021
 * Category: Pages
 * Title: TechnologyStackSection
 *
 */
.technology-stack-section {
  padding: 80px 0;
  background: #F5F7F7;
  overflow: hidden; }
  .technology-stack-section .title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 25px; }
    @media (max-width: 767px) {
      .technology-stack-section .title {
        font-size: 19px; } }
  .technology-stack-section .nav.nav-tabs {
    border: 0;
    margin-bottom: 30px; }
    .technology-stack-section .nav.nav-tabs .nav-item:not(:last-child) {
      margin-right: 30px; }
    .technology-stack-section .nav.nav-tabs .nav-link {
      border: 2px solid transparent;
      cursor: pointer;
      padding: 10px;
      font-size: 17px;
      font-weight: 600;
      color: #202124;
      background: #fff;
      box-shadow: 0 22px 25px 0 rgba(0, 0, 0, 0.06);
      border-radius: 8px;
      transition: all ease-in-out .3s; }
      .technology-stack-section .nav.nav-tabs .nav-link:hover, .technology-stack-section .nav.nav-tabs .nav-link.active {
        border-color: #fff;
        color: #007eff;
        box-shadow: 0 22px 25px 0 rgba(0, 126, 255, 0.21);
        background: #007eff; }
        .technology-stack-section .nav.nav-tabs .nav-link:hover img, .technology-stack-section .nav.nav-tabs .nav-link.active img {
          filter: brightness(0) invert(1); }
      .technology-stack-section .nav.nav-tabs .nav-link img {
        width: 40px;
        height: 40px;
        transition: all ease-in-out .2s; }
  .technology-stack-section .tab-content {
    position: relative; }
    @media (max-width: 991px) {
      .technology-stack-section .tab-content {
        margin-top: 30px; } }
    .technology-stack-section .tab-content::after {
      content: "";
      display: inline-block;
      position: absolute;
      top: 40px;
      right: -80px;
      background: url(https://www.cubix.co/themes/responsiv-clean/assets/images/seo-pages/mobile-seo/boxround-image.png) top right/50% 50% no-repeat;
      width: 192px;
      height: 385px; }
    .technology-stack-section .tab-content::before {
      content: "";
      display: inline-block;
      position: absolute;
      right: 50%;
      transform: translateX(80px);
      bottom: -80px;
      background: url(https://mobylogix.s3.amazonaws.com/v4/mobile-app-development-technology-stack.png) bottom right/30% 100% no-repeat;
      width: 100%;
      height: 400px; }
    .technology-stack-section .tab-content .tabs-content-caption {
      border-radius: 4px;
      background: #fff;
      box-shadow: 0 10px 45px -9px rgba(0, 0, 0, 0.2);
      padding: 50px;
      position: relative;
      z-index: 2; }
      @media (max-width: 767px) {
        .technology-stack-section .tab-content .tabs-content-caption {
          padding: 30px; } }
      .technology-stack-section .tab-content .tabs-content-caption .title-wrap {
        display: flex;
        align-items: center;
        margin-bottom: 30px; }
      .technology-stack-section .tab-content .tabs-content-caption .icon {
        width: 60px;
        height: 60px; }
        .technology-stack-section .tab-content .tabs-content-caption .icon + .title {
          margin-left: 20px; }
      .technology-stack-section .tab-content .tabs-content-caption .title {
        font-size: 24px;
        font-weight: 700;
        line-height: 1.2;
        margin-bottom: 0; }
      .technology-stack-section .tab-content .tabs-content-caption .description {
        font-size: 20px;
        line-height: 1.6;
        font-weight: 500; }
        .technology-stack-section .tab-content .tabs-content-caption .description:not(:last-child) {
          margin-bottom: 20px; }
