/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Tue Sep 28 2021
 * Category: Pages
 * Title: MainBanner
 *
 */
.main-banner {
  position: relative; }
  .main-banner .banner-slider .slick-arrow {
    width: 50px;
    height: 50px;
    background: #fff;
    color: #202124;
    z-index: 2; }
    .main-banner .banner-slider .slick-arrow:before {
      font-family: inherit; }
    .main-banner .banner-slider .slick-arrow:before {
      color: #202124; }
    .main-banner .banner-slider .slick-arrow.slick-prev {
      left: 0; }
    .main-banner .banner-slider .slick-arrow.slick-next {
      right: 0; }
    @media (max-width: 1050px) {
      .main-banner .banner-slider .slick-arrow {
        width: 30px;
        height: 30px; } }
    @media (max-width: 600px) {
      .main-banner .banner-slider .slick-arrow {
        display: none !important; } }
  .main-banner .banner-slider .slick-dots {
    bottom: 30px;
    z-index: 2; }
    .main-banner .banner-slider .slick-dots li {
      width: 10px;
      height: 10px; }
      .main-banner .banner-slider .slick-dots li button {
        background: #fff;
        padding: 0;
        width: 10px;
        height: 10px;
        border-radius: 19px; }
        .main-banner .banner-slider .slick-dots li button:before {
          display: none; }
      .main-banner .banner-slider .slick-dots li.slick-active {
        width: 15px;
        height: 15px; }
        .main-banner .banner-slider .slick-dots li.slick-active button {
          width: 15px;
          height: 15px;
          background: #007eff; }
  .main-banner .banner-caption-wrapper {
    padding: 230px 0 80px;
    height: 100vh;
    background-color: #000;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: -10px; }
    @media (max-width: 1440px) {
      .main-banner .banner-caption-wrapper {
        padding: 180px 0 80px; } }
    @media (max-width: 1200px) {
      .main-banner .banner-caption-wrapper {
        padding: 170px 0 80px; } }
    @media (max-width: 1050px) {
      .main-banner .banner-caption-wrapper {
        padding: 230px 0 80px; } }
    @media (max-width: 991px) {
      .main-banner .banner-caption-wrapper {
        padding: 140px 0 220px;
        height: 100%; } }
    @media (max-width: 567px) {
      .main-banner .banner-caption-wrapper {
        padding: 120px 0 60px;
        text-align: center;
        background-size: 100% 100%;
        background-position: 0;
        min-height: 535px; } }
  .main-banner .banner-caption {
    margin-right: 40px; }
    @media (max-width: 991px) {
      .main-banner .banner-caption {
        margin-right: 0;
        margin-bottom: 40px; } }
    .main-banner .banner-caption .main-title {
      font-size: 57px;
      line-height: 1.3;
      font-weight: 900;
      letter-spacing: -0.01em;
      color: #fff;
      margin-bottom: 0px; }
      .main-banner .banner-caption .main-title span {
        display: block; }
        .main-banner .banner-caption .main-title span:last-child {
          color: #007eff; }
      @media (max-width: 1400px) {
        .main-banner .banner-caption .main-title {
          font-size: 45px; } }
      @media (max-width: 991px) {
        .main-banner .banner-caption .main-title {
          font-size: 37px; } }
      @media (max-width: 767px) {
        .main-banner .banner-caption .main-title {
          font-size: 27px; } }
    .main-banner .banner-caption .description {
      font-size: 19px;
      font-weight: 500;
      line-height: 1.7;
      padding: 15px 0 25px 0;
      color: #fff; }
      @media (max-width: 991px) {
        .main-banner .banner-caption .description {
          font-size: 17px;
          padding: 15px 0; } }
    .main-banner .banner-caption .btn {
      min-height: 50px;
      min-width: 150px; }
      @media (max-width: 991px) {
        .main-banner .banner-caption .btn {
          min-height: 40px;
          min-width: 120px; } }
  .main-banner .badges-wrap {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-bottom: 50px; }
  .main-banner .badges-holder {
    display: flex;
    align-items: center;
    min-height: 72px; }
    @media (max-width: 991px) {
      .main-banner .badges-holder {
        margin-bottom: 15px;
        justify-content: center; } }
    @media (max-width: 567px) {
      .main-banner .badges-holder {
        display: none; } }
    .main-banner .badges-holder .title {
      margin: 0 30px 0 0;
      color: #fff;
      line-height: 24px;
      font-size: 15px; }
      @media (max-width: 1200px) {
        .main-banner .badges-holder .title {
          margin: 0 20px 0 0; } }
    .main-banner .badges-holder .badges-list {
      display: flex; }
      .main-banner .badges-holder .badges-list li {
        max-width: 80px; }
        .main-banner .badges-holder .badges-list li:not(:last-child) {
          margin: 0 30px 0 0; }
          @media (max-width: 1200px) {
            .main-banner .badges-holder .badges-list li:not(:last-child) {
              margin: 0 20px 0 0; } }
        @media (max-width: 1200px) {
          .main-banner .badges-holder .badges-list li {
            max-width: 65px; } }
    .main-banner .badges-holder.awards-holder {
      justify-content: flex-end; }
      @media (max-width: 991px) {
        .main-banner .badges-holder.awards-holder {
          justify-content: center; } }
