/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Fri Nov 05 2021
 * Category: Pages
 * Title: AwardsSection
 *
 */
.awards-section {
  padding: 80px 0;
  overflow: hidden; }
  .awards-section .main-heading {
    margin-bottom: 60px; }
  .awards-section .why-us-card {
    text-align: center; }
    .awards-section .why-us-card svg.icon {
      width: 100px;
      height: 100px;
      fill: #00cfff; }
      @media (max-width: 991px) {
        .awards-section .why-us-card svg.icon {
          width: 75px;
          height: 75px; } }
    .awards-section .why-us-card .icon {
      margin: 30px; }
    @media (max-width: 991px) {
      .awards-section .why-us-card .title {
        font-size: 27px; } }
    @media (max-width: 767px) {
      .awards-section .why-us-card .title {
        font-size: 22px; } }
