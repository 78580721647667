/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Thu Nov 11 2021
 * Category: Pages
 * Title: ProjectForm
 *
 */
.project-form {
  padding: 80px 0;
  background: #007eff;
  position: relative; }
  .project-form::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    background-image: url(https://mobylogix.s3.amazonaws.com/v4/tell-us-about-your-project.jpg);
    background-position: top right;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%; }
  .project-form .project-form-card {
    position: relative;
    z-index: 2;
    box-shadow: 0 5px 19px 0 rgba(50, 50, 50, 0.52); }
    .project-form .project-form-card.card {
      border-radius: 6px; }
      .project-form .project-form-card.card .card-body {
        padding: 50px; }
        @media (max-width: 567px) {
          .project-form .project-form-card.card .card-body {
            padding: 30px; } }
      .project-form .project-form-card.card .card-title {
        font-size: 35px;
        font-weight: 700;
        margin-bottom: 20px; }
        @media (max-width: 991px) {
          .project-form .project-form-card.card .card-title {
            font-size: 30px; } }
        @media (max-width: 767px) {
          .project-form .project-form-card.card .card-title {
            font-size: 25px; } }
      .project-form .project-form-card.card .form-inline .form-group {
        margin-bottom: 20px; }
      .project-form .project-form-card.card .form-inline label {
        font-size: 15px;
        font-weight: 500;
        color: #96A5AB; }
      .project-form .project-form-card.card .form-inline .form-control {
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-radius: 0;
        box-shadow: none;
        resize: none; }
      .project-form .project-form-card.card .btn {
        width: 100%; }
