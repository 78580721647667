/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Thu Nov 11 2021
 * Category: Pages
 * Title: InnerHighlightsSection
 *
 */
.inner-highlights-section {
  padding: 50px 0 0;
  background: #F5F7F7; }
  .inner-highlights-section .main-heading .description {
    font-size: 18px;
    font-weight: 500; }
  .inner-highlights-section .highlights-card.card {
    border-color: transparent;
    box-shadow: 0 25px 70px rgba(0, 0, 0, 0.12);
    border-radius: 12px;
    margin-bottom: 30px;
    transition: all ease-in-out .4s; }
    .inner-highlights-section .highlights-card.card .card-body {
      padding: 40px 20px; }
    .inner-highlights-section .highlights-card.card .icon-01 {
      fill: none;
      stroke: #f77a4c;
      stroke-width: 4.0403;
      stroke-miterlimit: 10; }
    .inner-highlights-section .highlights-card.card .icon-02 {
      fill: none;
      stroke: #f77a4c;
      stroke-width: 4.0403;
      stroke-linecap: round;
      stroke-linejoin: round; }
    .inner-highlights-section .highlights-card.card .icon {
      margin-top: -10px;
      width: 65px;
      margin-bottom: 10px;
      transition: all ease-in-out .4s; }
    .inner-highlights-section .highlights-card.card .title {
      font-size: 24px;
      font-weight: 700;
      line-height: 1.5;
      margin-bottom: 10px;
      transition: all ease-in-out .4s; }
    .inner-highlights-section .highlights-card.card .description {
      font-size: 16px;
      line-height: 1.65;
      margin-bottom: 0;
      font-weight: 500;
      transition: all ease-in-out .4s; }
    .inner-highlights-section .highlights-card.card:hover {
      background: #007eff; }
      .inner-highlights-section .highlights-card.card:hover .icon-01,
      .inner-highlights-section .highlights-card.card:hover .icon-02,
      .inner-highlights-section .highlights-card.card:hover .title,
      .inner-highlights-section .highlights-card.card:hover .description {
        stroke: #fff;
        color: #fff; }
