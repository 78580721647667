/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Thu Nov 11 2021
 * Category: Pages
 * Title: SubInnerBanner
 *
 */
.sub-inner-banner {
  padding: 140px 0 70px;
  background: url("https://www.cubix.co/themes/responsiv-clean/assets/images/curious-portfolio/Curious-background-header.png") no-repeat #a752e4;
  background-size: cover;
  background-position: center center;
  min-height: 750px;
  max-height: 750px;
  overflow: hidden; }
  .sub-inner-banner .main-title {
    text-align: center;
    font-size: 45px;
    line-height: 1.2;
    font-weight: 700;
    margin-bottom: 70px;
    color: #fff; }
    .sub-inner-banner .main-title span {
      display: block;
      color: #fff; }
  .sub-inner-banner .img-wrap {
    margin: 0 70px; }
