/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Wed Nov 10 2021
 * Category: Pages
 * Title: HighlightsSection
 *
 */
.highlights-section {
  padding: 80px 0 40px;
  background: #F5F7F7; }
  .highlights-section .main-heading .d-block {
    font-size: 32px; }
