/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Tue Oct 12 2021
 * Category: Pages
 * Title: Drawer
 *
 */
.drawer {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: #fff;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  transition: all ease-in-out .4s;
  overflow-y: scroll !important;
  -ms-overflow-style: none;
  scrollbar-width: none; }
  .drawer::-webkit-scrollbar {
    display: none; }
  .drawer .drawer-inner {
    position: relative;
    height: 100%;
    width: 100%;
    padding: 60px 0 30px; }
  .drawer .close-drawer-icon {
    position: absolute;
    top: 40px;
    right: 45px;
    z-index: 2;
    cursor: pointer; }
  .drawer .main-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap; }
    .drawer .main-menu li {
      flex: 1 1 100%;
      text-align: center;
      min-height: 60px;
      line-height: 60px;
      margin: 0 20px; }
      .drawer .main-menu li a {
        display: block;
        color: #202124; }
      .drawer .main-menu li.menu-dropdown-holder > a {
        padding-right: 0; }
        .drawer .main-menu li.menu-dropdown-holder > a:before {
          border-color: #202124; }
      .drawer .main-menu li.menu-dropdown-holder:after {
        top: 30px; }
      .drawer .main-menu li.menu-dropdown-holder .menu-dropdown {
        transition: all ease-in-out .4s;
        position: relative;
        opacity: 0;
        visibility: hidden;
        width: 100%;
        min-width: calc(100% + 10px);
        top: 0;
        height: 0;
        padding: 0; }
        @media (max-width: 991px) {
          .drawer .main-menu li.menu-dropdown-holder .menu-dropdown .dropdown-sub-heading .title a::after {
            width: 15%;
            left: 50%;
            transform: translate(-50px, 0px); } }
        @media (max-width: 991px) {
          .drawer .main-menu li.menu-dropdown-holder .menu-dropdown .dropdown-sub-heading .dropdown-list {
            margin: 0; } }
        @media (max-width: 991px) {
          .drawer .main-menu li.menu-dropdown-holder .menu-dropdown .dropdown-sub-heading.dropdown-sub-heading-pd {
            padding: 0; } }
        @media (max-width: 991px) {
          .drawer .main-menu li.menu-dropdown-holder .menu-dropdown .dropdown-sub-heading .sub-heading {
            position: relative; }
            .drawer .main-menu li.menu-dropdown-holder .menu-dropdown .dropdown-sub-heading .sub-heading .icon {
              position: absolute;
              top: 0;
              left: 0; } }
        @media (max-width: 991px) {
          .drawer .main-menu li.menu-dropdown-holder .menu-dropdown > div {
            margin-bottom: 20px; }
            .drawer .main-menu li.menu-dropdown-holder .menu-dropdown > div:last-child {
              margin-bottom: 0; } }
        .drawer .main-menu li.menu-dropdown-holder .menu-dropdown.show-menu-dropdown {
          opacity: 1;
          left: 0;
          visibility: visible;
          display: flex;
          height: 100%;
          padding: 40px; }
          @media (max-width: 567px) {
            .drawer .main-menu li.menu-dropdown-holder .menu-dropdown.show-menu-dropdown {
              padding: 20px 10px; } }
  .drawer.show-drawer {
    visibility: visible;
    opacity: 1; }
  .drawer .menu-dropdown-wrap {
    padding: 20px 0 !important;
    text-align: left;
    overflow: scroll !important;
    -ms-overflow-style: none;
    scrollbar-width: none; }
    .drawer .menu-dropdown-wrap::-webkit-scrollbar {
      display: none; }
    @media (max-width: 991px) {
      .drawer .menu-dropdown-wrap {
        padding: 0 !important; } }
    @media (max-width: 991px) {
      .drawer .menu-dropdown-wrap::after {
        display: none; } }
    .drawer .menu-dropdown-wrap li {
      text-align: left; }
    .drawer .menu-dropdown-wrap .container {
      max-width: 100%; }
    .drawer .menu-dropdown-wrap .dropdown-col-wrap {
      margin: 0 !important; }
      .drawer .menu-dropdown-wrap .dropdown-col-wrap .main-title {
        padding: 0 !important; }
      .drawer .menu-dropdown-wrap .dropdown-col-wrap .dropdown-col-list li {
        margin: 0 !important; }
      @media (max-width: 567px) {
        .drawer .menu-dropdown-wrap .dropdown-col-wrap .dropdown-col-list a {
          padding: 10px !important; } }
