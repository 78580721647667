/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Thu Nov 04 2021
 * Category: Pages
 * Title: ProductSection
 *
 */
.product-section {
  padding: 80px 0;
  overflow: hidden; }
  @media (max-width: 480px) {
    .product-section {
      padding: 40px 0; } }
  @media (max-width: 767px) {
    .product-section .main-heading {
      margin: 0 10px; } }
  @media (max-width: 600px) {
    .product-section .main-heading {
      margin: 0 60px; } }
  @media (max-width: 480px) {
    .product-section .main-heading {
      margin: 0; } }
  .product-section .row-holder {
    padding: 80px 0 0; }
    @media (max-width: 480px) {
      .product-section .row-holder {
        padding: 40px 0 0; }
        .product-section .row-holder:last-child {
          padding: 0; } }
  .product-section .slick-arrow {
    width: 50px;
    height: 50px;
    background: #fff;
    color: #202124;
    z-index: 2; }
    @media (max-width: 991px) {
      .product-section .slick-arrow {
        display: none !important; } }
    .product-section .slick-arrow:before {
      font-family: inherit; }
    .product-section .slick-arrow:before {
      color: #202124; }
    .product-section .slick-arrow.slick-prev {
      left: -60px; }
    .product-section .slick-arrow.slick-next {
      right: -60px; }
  .product-section .product-imgs {
    text-align: center;
    position: relative; }
    @media (max-width: 991px) {
      .product-section .product-imgs {
        margin-bottom: 60px; } }
    .product-section .product-imgs figure {
      margin: 0; }
    .product-section .product-imgs .product-img-1 {
      position: absolute;
      top: 50px;
      right: 10px; }
      @media (max-width: 991px) {
        .product-section .product-imgs .product-img-1 {
          width: 200px; } }
  .product-section .product-caption {
    min-height: 500px; }
    @media (max-width: 991px) {
      .product-section .product-caption {
        min-height: 100%; } }
    @media (max-width: 480px) {
      .product-section .product-caption {
        margin-bottom: 40px; } }
    .product-section .product-caption .product-icon {
      margin-bottom: 20px; }
      .product-section .product-caption .product-icon img {
        height: 80px; }
        @media (max-width: 991px) {
          .product-section .product-caption .product-icon img {
            height: 60px; } }
        @media (max-width: 767px) {
          .product-section .product-caption .product-icon img {
            height: 40px; } }
    .product-section .product-caption .sub-title {
      font-weight: 500;
      text-transform: uppercase;
      color: #f77a4c;
      display: inline-block;
      margin-bottom: 5px;
      font-size: 13px; }
    .product-section .product-caption .title {
      font-size: 40px;
      font-weight: 700;
      margin-bottom: 20px; }
      @media (max-width: 1380px) {
        .product-section .product-caption .title {
          font-size: 33px; } }
      @media (max-width: 1110px) {
        .product-section .product-caption .title {
          font-size: 28px; } }
      @media (max-width: 991px) {
        .product-section .product-caption .title {
          font-size: 22px; } }
      @media (max-width: 767px) {
        .product-section .product-caption .title {
          font-size: 19px; } }
      @media (max-width: 480px) {
        .product-section .product-caption .title {
          font-size: 16px;
          line-height: 1.5; } }
    .product-section .product-caption .description {
      font-size: 18px;
      line-height: 1.6;
      margin-bottom: 40px;
      margin-right: 130px;
      color: #000; }
      @media (max-width: 1100px) {
        .product-section .product-caption .description {
          font-size: 17px;
          margin-right: 0px; } }
      @media (max-width: 480px) {
        .product-section .product-caption .description {
          font-size: 14px;
          margin-bottom: 20px; } }
    .product-section .product-caption .btn {
      min-height: 55px;
      min-width: 200px;
      font-weight: 600; }
      @media (max-width: 1100px) {
        .product-section .product-caption .btn {
          min-height: 50px; } }
      @media (max-width: 480px) {
        .product-section .product-caption .btn {
          min-height: 40px;
          width: 100%;
          font-size: 14px; } }
