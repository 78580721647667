/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Wed Nov 17 2021
 * Category: Pages
 * Title: AboutBanner
 *
 */
.about-banner {
  position: relative;
  padding: 100px 0 70px;
  min-height: 650px;
  max-height: 650px;
  background: url("https://mobylogix.s3.amazonaws.com/v4/about.jpg") no-repeat;
  background-size: cover;
  background-position: center center; }
  .about-banner .about-banner-caption {
    position: relative;
    z-index: 2;
    padding-top: 60px;
    padding-right: 60px; }
  .about-banner .title {
    font-size: 45px;
    font-weight: 700;
    line-height: 1.25;
    color: #fff; }
    .about-banner .title span {
      color: #fff; }
  .about-banner .description {
    font-size: 21px;
    font-weight: 500;
    color: #fff;
    line-height: 1.5;
    margin: 25px 0 45px; }
    .about-banner .description span {
      color: #fff; }
  .about-banner .btn {
    min-height: 57px;
    min-width: 200px;
    font-weight: 600;
    font-size: 17px;
    text-transform: uppercase;
    border-radius: 2px;
    margin-bottom: 30px; }
    .about-banner .btn.btn-primary {
      background: #fff;
      color: #007eff;
      border: transparent; }
      .about-banner .btn.btn-primary:hover {
        background: #fff; }
