/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Tue Sep 28 2021
 * Category: Pages
 * Title: Header
 *
 */
.main-header {
  transition: all ease-in-out .15s;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 9; }
  .main-header.fixed {
    background-color: #fff;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05); }
    .main-header.fixed .main-menu li:hover a {
      color: #007eff; }
      .main-header.fixed .main-menu li:hover a .link-text {
        color: #007eff; }
      .main-header.fixed .main-menu li:hover a .link-icon {
        color: #007eff;
        fill: #007eff; }
    .main-header.fixed .main-menu li:hover.menu-dropdown-holder:after {
      border-color: #007eff; }
    .main-header.fixed .main-menu li a {
      color: #007eff; }
      .main-header.fixed .main-menu li a .link-text {
        color: #007eff; }
      .main-header.fixed .main-menu li a .link-icon {
        color: #007eff;
        fill: #007eff; }
      .main-header.fixed .main-menu li a.drawer-icon svg {
        fill: #007eff;
        stroke: #007eff; }
      .main-header.fixed .main-menu li a:before {
        border-color: #007eff; }
    .main-header.fixed .main-menu li.menu-dropdown-holder:after {
      border-left-color: #007eff;
      border-top-color: #007eff; }
    .main-header.fixed .main-menu.second-menu li a.btn.btn-primary {
      color: #fff; }
      .main-header.fixed .main-menu.second-menu li a.btn.btn-primary:hover {
        color: #007eff; }
        .main-header.fixed .main-menu.second-menu li a.btn.btn-primary:hover span {
          color: #007eff; }
        .main-header.fixed .main-menu.second-menu li a.btn.btn-primary:hover svg {
          fill: #007eff; }
          .main-header.fixed .main-menu.second-menu li a.btn.btn-primary:hover svg line {
            stroke: #007eff; }
    .main-header.fixed .main-menu.second-menu li a.btn span {
      color: #fff; }
    .main-header.fixed .main-menu.second-menu li a.btn svg {
      fill: #fff; }
      .main-header.fixed .main-menu.second-menu li a.btn svg line {
        stroke: #fff; }
  .main-header.on-hover {
    background-color: #fff;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05); }
    .main-header.on-hover .main-menu li:hover a {
      color: #007eff; }
      .main-header.on-hover .main-menu li:hover a .link-text {
        color: #007eff; }
      .main-header.on-hover .main-menu li:hover a .link-icon {
        color: #007eff;
        fill: #007eff; }
    .main-header.on-hover .main-menu li:hover.menu-dropdown-holder:after {
      border-color: #007eff; }
    .main-header.on-hover .main-menu li a {
      color: #007eff; }
      .main-header.on-hover .main-menu li a .link-text {
        color: #007eff; }
      .main-header.on-hover .main-menu li a .link-icon {
        color: #007eff;
        fill: #007eff; }
      .main-header.on-hover .main-menu li a:before {
        border-color: #007eff; }
    .main-header.on-hover .main-menu li.menu-dropdown-holder:after {
      border-left-color: #007eff;
      border-top-color: #007eff; }
    .main-header.on-hover .main-menu.second-menu li a.btn.btn-primary {
      color: #fff; }
      .main-header.on-hover .main-menu.second-menu li a.btn.btn-primary:hover {
        color: #007eff; }
        .main-header.on-hover .main-menu.second-menu li a.btn.btn-primary:hover span {
          color: #007eff; }
        .main-header.on-hover .main-menu.second-menu li a.btn.btn-primary:hover svg {
          fill: #007eff; }
          .main-header.on-hover .main-menu.second-menu li a.btn.btn-primary:hover svg line {
            stroke: #007eff; }
    .main-header.on-hover .main-menu.second-menu li a.btn span {
      color: #fff; }
    .main-header.on-hover .main-menu.second-menu li a.btn svg {
      fill: #fff; }
      .main-header.on-hover .main-menu.second-menu li a.btn svg line {
        stroke: #fff; }
  .main-header .dropdown-wrap {
    position: relative; }

.nav-holder {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.logo {
  transition: all ease-in-out .4s; }
  .logo a {
    display: inline-block;
    transition: all ease-in-out .4s; }
    .logo a img {
      transition: all ease-in-out .4s; }
  @media (max-width: 1400px) {
    .logo {
      max-width: 150px; } }
  @media (max-width: 566px) {
    .logo {
      max-width: 130px; } }

.main-menu {
  display: flex; }
  @media (max-width: 1200px) {
    .main-menu {
      display: none; } }
  .main-menu li {
    padding: 0 20px;
    min-height: 100px;
    line-height: 100px;
    font-weight: 700;
    font-size: 16px;
    cursor: pointer; }
    @media (max-width: 1400px) {
      .main-menu li {
        padding: 0 10px;
        font-size: 15px; } }
    @media (max-width: 566px) {
      .main-menu li {
        min-height: 80px;
        line-height: 80px; } }
    .main-menu li .uppercase {
      text-transform: uppercase;
      display: flex;
      align-items: center; }
      @media (max-width: 566px) {
        .main-menu li .uppercase {
          display: inline-block; } }
      .main-menu li .uppercase .link-icon {
        display: inline-block !important;
        width: 15px;
        margin-top: -1px; }
        .main-menu li .uppercase .link-icon + .link-text {
          margin-left: 10px; }
    .main-menu li:hover a {
      color: #007eff; }
      .main-menu li:hover a .link-text {
        color: #007eff; }
      .main-menu li:hover a .link-icon {
        color: #007eff;
        fill: #007eff; }
    .main-menu li:hover.menu-dropdown-holder a:before {
      border-color: #007eff; }
    .main-menu li:hover.menu-dropdown-holder .menu-dropdown {
      display: block; }
    .main-menu li a {
      color: #fff; }
      .main-menu li a.drawer-icon {
        display: inline-block; }
        @media (min-width: 1200px) {
          .main-menu li a.drawer-icon {
            display: none; } }
        .main-menu li a.drawer-icon svg {
          fill: #fff;
          stroke: #fff; }
    .main-menu li.menu-dropdown-holder > a {
      position: relative;
      padding-right: 10px; }
      .main-menu li.menu-dropdown-holder > a:before {
        content: '';
        position: absolute;
        border-left: 1px solid #fff;
        border-top: 1px solid #fff;
        width: 5px;
        height: 5px;
        top: calc(50% + -4px);
        right: 0;
        transform: rotate(-135deg);
        transition: all ease-in-out .4s; }
    .main-menu li.menu-dropdown-holder .menu-dropdown {
      border-top: 1px solid #E3E8EA;
      transition: all ease-in-out .4s;
      position: absolute;
      line-height: 20px;
      bottom: -500px;
      right: 0;
      z-index: 9;
      display: none;
      height: 505px;
      width: 100vw;
      background: #fff;
      box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05); }
      @media (max-width: 991px) {
        .main-menu li.menu-dropdown-holder .menu-dropdown {
          flex-wrap: wrap; } }
      .main-menu li.menu-dropdown-holder .menu-dropdown > div {
        flex: 1 1 100%; }
      .main-menu li.menu-dropdown-holder .menu-dropdown .menu-dropdown-wrap {
        padding: 40px 0;
        position: relative; }
        .main-menu li.menu-dropdown-holder .menu-dropdown .menu-dropdown-wrap:after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          width: 32.8%;
          height: 100%;
          background: #F5F7F7; }
          @media (max-width: 1440px) {
            .main-menu li.menu-dropdown-holder .menu-dropdown .menu-dropdown-wrap:after {
              width: 29.8%; } }
      .main-menu li.menu-dropdown-holder .menu-dropdown .dropdown-heading {
        position: relative;
        z-index: 2; }
        .main-menu li.menu-dropdown-holder .menu-dropdown .dropdown-heading .title {
          font-size: 22px;
          margin-bottom: 15px;
          font-weight: 500; }
        .main-menu li.menu-dropdown-holder .menu-dropdown .dropdown-heading .description {
          margin-bottom: 0;
          font-weight: 400;
          color: rgba(32, 33, 36, 0.7);
          padding: 10px 0;
          font-size: 15px; }
      .main-menu li.menu-dropdown-holder .menu-dropdown .dropdown-col-wrap {
        position: relative;
        z-index: 2;
        margin: 0 -10px; }
        .main-menu li.menu-dropdown-holder .menu-dropdown .dropdown-col-wrap .main-title {
          font-size: 18px;
          margin-bottom: 15px;
          font-weight: 600;
          padding: 0 20px; }
        .main-menu li.menu-dropdown-holder .menu-dropdown .dropdown-col-wrap .dropdown-col-list li {
          padding: 0;
          line-height: 20px;
          min-height: 100%; }
          .main-menu li.menu-dropdown-holder .menu-dropdown .dropdown-col-wrap .dropdown-col-list li:not(:last-child) {
            margin-bottom: 10px; }
        .main-menu li.menu-dropdown-holder .menu-dropdown .dropdown-col-wrap .dropdown-col-list a {
          padding: 10px 20px;
          display: block; }
          .main-menu li.menu-dropdown-holder .menu-dropdown .dropdown-col-wrap .dropdown-col-list a:hover {
            background: #F5F7F7; }
            .main-menu li.menu-dropdown-holder .menu-dropdown .dropdown-col-wrap .dropdown-col-list a:hover .title {
              text-decoration: underline;
              color: #007eff; }
          .main-menu li.menu-dropdown-holder .menu-dropdown .dropdown-col-wrap .dropdown-col-list a .title {
            font-weight: 500;
            font-size: 16px;
            margin-bottom: 10px;
            color: #007eff;
            transition: all ease-in-out .4s;
            line-height: 1.2; }
          .main-menu li.menu-dropdown-holder .menu-dropdown .dropdown-col-wrap .dropdown-col-list a p {
            font-size: 15px;
            line-height: 1.3;
            margin: 0;
            font-weight: 400; }
    .main-menu li .btn {
      padding: 15px 20px;
      border-radius: 40px; }
  .main-menu.second-menu {
    align-items: center; }
    @media (max-width: 1200px) {
      .main-menu.second-menu {
        display: flex; } }
    .main-menu.second-menu li:last-child {
      margin: 0; }
    .main-menu.second-menu li:nth-child(2) {
      margin: 0; }
    .main-menu.second-menu li a.btn {
      min-width: 120px;
      font-weight: 700;
      text-transform: uppercase;
      display: inline-block; }
      .main-menu.second-menu li a.btn.btn-primary {
        color: #fff; }
        .main-menu.second-menu li a.btn.btn-primary:hover {
          color: #007eff; }
          .main-menu.second-menu li a.btn.btn-primary:hover span {
            color: #007eff; }
          .main-menu.second-menu li a.btn.btn-primary:hover svg {
            fill: #007eff; }
            .main-menu.second-menu li a.btn.btn-primary:hover svg line {
              stroke: #007eff; }
      .main-menu.second-menu li a.btn span {
        color: #fff; }
      .main-menu.second-menu li a.btn svg {
        transition: all ease-in-out .4s;
        fill: #fff; }
        .main-menu.second-menu li a.btn svg line {
          stroke: #fff; }
      @media (max-width: 991px) {
        .main-menu.second-menu li a.btn {
          padding: 15px;
          font-size: 14px; } }
      @media (max-width: 566px) {
        .main-menu.second-menu li a.btn {
          min-width: 100%;
          padding: 10px;
          border-radius: 4px; } }
    .main-menu.second-menu li .link-text {
      transition: all ease-in-out .4s;
      color: #fff;
      display: none; }
      @media (min-width: 566px) {
        .main-menu.second-menu li .link-text {
          display: inline-block; } }
    .main-menu.second-menu li .link-icon {
      transition: all ease-in-out .4s;
      color: #fff;
      fill: #fff;
      display: inline-block; }
      @media (min-width: 566px) {
        .main-menu.second-menu li .link-icon {
          display: none; } }
