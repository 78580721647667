/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Tue Nov 09 2021
 * Category: Pages
 * Title: InnerBanerForm
 *
 */
.inner-baner-form.card {
  max-width: 75%;
  margin-left: auto;
  border-radius: 6px; }
  @media (max-width: 1400px) {
    .inner-baner-form.card {
      max-width: 85%; } }
  @media (max-width: 991px) {
    .inner-baner-form.card {
      display: none; } }
  .inner-baner-form.card .card-body {
    padding: 50px; }
  .inner-baner-form.card .card-title {
    font-size: 35px;
    font-weight: 700;
    margin-bottom: 20px; }
  .inner-baner-form.card .form-inline .form-group {
    margin-bottom: 20px; }
  .inner-baner-form.card .form-inline label {
    font-size: 15px;
    font-weight: 500;
    color: #96A5AB; }
  .inner-baner-form.card .form-inline .form-control {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    box-shadow: none;
    resize: none; }
  .inner-baner-form.card .btn {
    width: 100%; }
