/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Tue Nov 09 2021
 * Category: Pages
 * Title: CreativeSection
 *
 */
.creative-section {
  background: #F5F7F7; }
  .creative-section .container-fluid {
    padding: 0 !important; }
  .creative-section .main-heading {
    text-align: left;
    margin-bottom: 50px; }
    .creative-section .main-heading .main-title {
      margin-bottom: 35px; }
    .creative-section .main-heading .description {
      font-size: 20px;
      line-height: 1.6;
      margin-bottom: 0; }
  .creative-section .nav.nav-tabs {
    border: 0; }
    .creative-section .nav.nav-tabs .nav-link {
      cursor: pointer;
      border: 0;
      padding: 25px 35px;
      font-size: 17px;
      font-weight: 600;
      color: #202124;
      background: transparent; }
      .creative-section .nav.nav-tabs .nav-link.active {
        color: #007eff;
        border-bottom: 2px solid #007eff; }
      .creative-section .nav.nav-tabs .nav-link:hover {
        color: #007eff; }
  .creative-section .tab-content figure {
    margin: 0; }
  @media (max-width: 991px) {
    .creative-section .tabs-content {
      padding: 30px; } }
  .creative-section .tabs-content-caption {
    margin-top: 30px; }
    .creative-section .tabs-content-caption .title {
      font-size: 24px;
      font-weight: 700;
      line-height: 1.2;
      margin-bottom: 15px; }
      @media (max-width: 767px) {
        .creative-section .tabs-content-caption .title {
          font-size: 19px; } }
    .creative-section .tabs-content-caption .description {
      font-size: 20px;
      line-height: 1.6;
      margin-bottom: 0; }
      @media (max-width: 767px) {
        .creative-section .tabs-content-caption .description {
          font-size: 16px; } }
