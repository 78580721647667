/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Tue Sep 28 2021
 * Category: Pages
 * Title: WhyUsSection
 *
 */
.why-us-section {
  padding: 60px 0 80px;
  background: -webkit-gradient(linear, left top, right top, from(#007eff), to(#a752e4));
  background: linear-gradient(to right, #007eff 0, #a752e4 100%);
  text-align: center; }
  @media (max-width: 480px) {
    .why-us-section {
      padding: 40px 0; } }
  .why-us-section .icon-01 {
    fill: none;
    stroke: #fff;
    stroke-width: 3.0403;
    stroke-miterlimit: 10; }
  .why-us-section .icon-02 {
    fill: none;
    stroke: #fff;
    stroke-width: 3.0403;
    stroke-linecap: round;
    stroke-linejoin: round; }
  .why-us-section .main-heading {
    padding-bottom: 50px; }
    @media (max-width: 480px) {
      .why-us-section .main-heading {
        padding-bottom: 30px; } }
    .why-us-section .main-heading .main-title {
      color: #fff; }
  .why-us-section .why-us-card {
    padding: 50px 0;
    margin: 0 25px;
    cursor: pointer;
    transition: all ease-in-out .4s;
    border-radius: 6px; }
    @media (max-width: 767px) {
      .why-us-section .why-us-card {
        padding: 40px; } }
    @media (max-width: 600px) {
      .why-us-section .why-us-card {
        max-width: 55%;
        margin: 0 auto;
        padding: 30px; } }
    @media (max-width: 400px) {
      .why-us-section .why-us-card {
        max-width: 60%;
        margin: 0 auto; } }
    @media (max-width: 370px) {
      .why-us-section .why-us-card {
        max-width: 70%;
        margin: 0 auto; } }
    .why-us-section .why-us-card .icon {
      fill: #fff;
      width: 65px;
      margin-bottom: 10px;
      transition: all ease-in-out .4s; }
      @media (max-width: 767px) {
        .why-us-section .why-us-card .icon {
          width: 55px; } }
    .why-us-section .why-us-card .title {
      font-size: 20px;
      font-weight: 700;
      line-height: 1.4;
      margin: 0 auto;
      color: #fff;
      display: table;
      width: min-content;
      transition: all ease-in-out .4s; }
      @media (max-width: 991px) {
        .why-us-section .why-us-card .title {
          font-size: 18px; } }
      @media (max-width: 767px) {
        .why-us-section .why-us-card .title {
          font-size: 16px; } }
    .why-us-section .why-us-card:hover {
      background: #fff; }
      .why-us-section .why-us-card:hover .icon {
        fill: #007eff; }
      .why-us-section .why-us-card:hover .icon-01 {
        stroke: #007eff; }
      .why-us-section .why-us-card:hover .icon-02 {
        stroke: #007eff; }
      .why-us-section .why-us-card:hover .title {
        color: #007eff; }
