/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Thu Nov 11 2021
 * Category: Pages
 * Title: DiscoverSection
 *
 */
.discover-section .half-content-wrap {
  padding: 80px 0 50px; }

.discover-section .img-wrap {
  margin-bottom: 30px; }

.discover-section .single-description {
  padding-top: 80px; }

.discover-section .big-img {
  background: url("https://www.cubix.co/themes/responsiv-clean/assets/images/curious-portfolio/last-section-bg.png") no-repeat;
  background-size: cover;
  background-position: center;
  height: 750px; }
