/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Wed Nov 10 2021
 * Category: Pages
 * Title: ProcessSection
 *
 */
.process-section {
  padding: 80px 0; }
  @media (max-width: 991px) {
    .process-section .main-heading.alter-main-heading .description {
      margin: 20px 0 0;
      max-width: 100%; } }
  .process-section .process-box-wrap {
    margin-bottom: -30px; }
  .process-section .process-box {
    margin-bottom: 30px;
    position: relative; }
    .process-section .process-box:after {
      content: "";
      display: inline-block;
      position: absolute;
      background: url(https://mobylogix.s3.amazonaws.com/v4/app-development-process.png) center/100% 100% no-repeat;
      height: 105px;
      width: 73px;
      top: 20%;
      right: 20px; }
    .process-section .process-box .title {
      font-size: 90px;
      font-weight: 700;
      line-height: 1.3;
      margin-bottom: 0; }
      @media (max-width: 991px) {
        .process-section .process-box .title {
          font-size: 80px; } }
      @media (max-width: 767px) {
        .process-section .process-box .title {
          font-size: 70px; } }
      @media (max-width: 567px) {
        .process-section .process-box .title {
          font-size: 60px; } }
    .process-section .process-box .sub-title {
      font-size: 23px;
      font-weight: 800; }
    .process-section .process-box .hr-line {
      width: 55%;
      margin-left: 0;
      border-color: #202124; }
    .process-section .process-box .process-list {
      font-size: 18px;
      line-height: 32px;
      text-indent: 10px;
      list-style-type: square;
      margin-left: 20px; }
    .process-section .process-box.process-box-after-none:after {
      display: none; }
      @media (max-width: 991px) {
        .process-section .process-box.process-box-after-none:after {
          display: block; } }
