/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Thu Nov 11 2021
 * Category: Pages
 * Title: ParallaxSection
 *
 */
.parallax-section {
  background: url("https://www.cubix.co/themes/responsiv-clean/assets/images/curious-portfolio/Curious-section-bottom-bg.png") no-repeat;
  background-size: contain;
  background-attachment: fixed;
  background-position: center;
  overflow: hidden; }
  .parallax-section .img-wrap {
    margin: 0; }
    .parallax-section .img-wrap img {
      position: relative;
      bottom: -20px;
      width: 80%; }
  .parallax-section .parallax-caption {
    text-align: center; }
    .parallax-section .parallax-caption h2 {
      display: inline-block;
      background: #202124;
      color: #fff;
      padding: 8px;
      margin: 8px;
      font-size: 42px;
      line-height: 1.2; }
    .parallax-section .parallax-caption h3 {
      display: inline-block;
      background: #202124;
      color: #fff;
      padding: 8px;
      margin: 5px;
      font-size: 35px;
      line-height: 1.3; }
