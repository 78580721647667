/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Thu Nov 04 2021
 * Category: Pages
 * Title: TestimonialsSection
 *
 */
.testimonials-section {
  padding: 80px 0;
  overflow: hidden;
  background: #F5F7F7; }
  @media (max-width: 480px) {
    .testimonials-section {
      padding: 40px 0; } }
  .testimonials-section .testimonials-slider .slick-arrow {
    width: 50px;
    height: 50px;
    background: #fff;
    color: #202124;
    z-index: 2; }
    .testimonials-section .testimonials-slider .slick-arrow:before {
      font-family: inherit; }
    .testimonials-section .testimonials-slider .slick-arrow:before {
      color: #202124; }
    .testimonials-section .testimonials-slider .slick-arrow.slick-prev {
      left: -60px; }
    .testimonials-section .testimonials-slider .slick-arrow.slick-next {
      right: -60px; }
    @media (max-width: 600px) {
      .testimonials-section .testimonials-slider .slick-arrow {
        display: none !important; } }
  .testimonials-section .testimonials-slider .slick-dots li {
    width: 10px;
    height: 10px; }
    .testimonials-section .testimonials-slider .slick-dots li button {
      background: #96A5AB;
      padding: 0;
      width: 10px;
      height: 10px;
      border-radius: 19px; }
      .testimonials-section .testimonials-slider .slick-dots li button:before {
        display: none; }
    .testimonials-section .testimonials-slider .slick-dots li.slick-active {
      width: 15px;
      height: 15px; }
      .testimonials-section .testimonials-slider .slick-dots li.slick-active button {
        width: 15px;
        height: 15px;
        background: #007eff; }
  .testimonials-section .testimonials-slider {
    margin: 60px 0; }
    @media (max-width: 480px) {
      .testimonials-section .testimonials-slider {
        margin: 40px 0; } }
  .testimonials-section .testimonials-caption {
    text-align: center;
    line-height: 1.4; }
    .testimonials-section .testimonials-caption .title {
      font-size: 22px;
      font-weight: 700; }
      @media (max-width: 1110px) {
        .testimonials-section .testimonials-caption .title {
          font-size: 20px; } }
      @media (max-width: 991px) {
        .testimonials-section .testimonials-caption .title {
          font-size: 18px; } }
      @media (max-width: 767px) {
        .testimonials-section .testimonials-caption .title {
          font-size: 16px; } }
      @media (max-width: 480px) {
        .testimonials-section .testimonials-caption .title {
          font-size: 14px; } }
    .testimonials-section .testimonials-caption p {
      font-size: 18px; }
      @media (max-width: 1110px) {
        .testimonials-section .testimonials-caption p {
          font-size: 16px; } }
      @media (max-width: 767px) {
        .testimonials-section .testimonials-caption p {
          font-size: 14px; } }
      @media (max-width: 480px) {
        .testimonials-section .testimonials-caption p {
          font-size: 13px; } }
  .testimonials-section .testimonials-content {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 30px; }
    @media (max-width: 1110px) {
      .testimonials-section .testimonials-content {
        font-size: 22px; } }
    @media (max-width: 991px) {
      .testimonials-section .testimonials-content {
        font-size: 20px; } }
    @media (max-width: 767px) {
      .testimonials-section .testimonials-content {
        font-size: 18px; } }
    @media (max-width: 480px) {
      .testimonials-section .testimonials-content {
        font-size: 16px; } }
  .testimonials-section .clutch-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px; }
    @media (max-width: 1110px) {
      .testimonials-section .clutch-wrap {
        font-size: 14px; } }
    .testimonials-section .clutch-wrap a {
      display: flex;
      align-items: center;
      padding-right: 20px; }
      @media (max-width: 480px) {
        .testimonials-section .clutch-wrap a {
          padding-right: 0;
          padding-top: 20px; } }
      .testimonials-section .clutch-wrap a .img-wrap {
        margin-right: 10px; }
      .testimonials-section .clutch-wrap a span {
        display: inline-block; }
      .testimonials-section .clutch-wrap a img {
        width: 30px; }
      .testimonials-section .clutch-wrap a .text {
        color: #007eff;
        font-weight: 700;
        position: relative;
        transition: all ease-in-out .4s; }
        .testimonials-section .clutch-wrap a .text:after {
          content: '';
          display: inline-block;
          width: 100%;
          height: 1px;
          position: absolute;
          bottom: 0;
          left: 0;
          background-color: #007eff;
          transform-origin: right center;
          transform: scaleX(0);
          transition: all ease-in-out .4s; }
        .testimonials-section .clutch-wrap a .text:hover:after {
          transform-origin: left center;
          transform: scaleX(1); }
    .testimonials-section .clutch-wrap .top-text {
      font-weight: 500;
      padding-left: 20px;
      border-left: 1px solid #E3E8EA; }
      @media (max-width: 480px) {
        .testimonials-section .clutch-wrap .top-text {
          display: none; } }
