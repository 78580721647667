/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Mon Nov 08 2021
 * Category: Pages
 * Title: PartnerSection
 *
 */
.partner-section {
  background: #F5F7F7;
  padding: 120px 0 80px;
  border-bottom: 1px solid #E3E8EA; }
  @media (max-width: 480px) {
    .partner-section {
      padding: 40px 0 0; } }
  .partner-section .main-heading {
    margin-bottom: 60px; }
  .partner-section .partners-box {
    text-align: center;
    padding-bottom: 40px; }
    .partner-section .partners-box .img-wrap {
      margin-bottom: 10px; }
      .partner-section .partners-box .img-wrap img {
        width: 150px; }
    .partner-section .partners-box .title {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 0; }
      @media (max-width: 991px) {
        .partner-section .partners-box .title {
          font-size: 18px; } }
      @media (max-width: 767px) {
        .partner-section .partners-box .title {
          font-size: 16px; } }
