/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Wed Nov 10 2021
 * Category: Pages
 * Title: InsightsSection
 *
 */
.insights-section {
  padding: 80px 0 50px; }
  .insights-section .insights-card.card {
    border: 0; }
    .insights-section .insights-card.card .card-body {
      padding: 0; }
    .insights-section .insights-card.card figure {
      margin-bottom: 0; }
      .insights-section .insights-card.card figure img {
        width: 100%; }
    .insights-section .insights-card.card .insights-card-caption {
      position: relative;
      z-index: 2;
      margin: -30px 20px 0;
      background: #fff;
      padding: 20px;
      box-shadow: 0 5px 35px 0 rgba(0, 0, 0, 0.2);
      transition: all ease-in-out .4s;
      position: relative; }
      .insights-section .insights-card.card .insights-card-caption:after {
        content: '';
        display: inline-block;
        width: 100%;
        height: 5px;
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: #007eff;
        transform-origin: right center;
        transform: scaleX(0);
        transition: all ease-in-out .4s; }
      .insights-section .insights-card.card .insights-card-caption:hover {
        transform: scale(1.03); }
        .insights-section .insights-card.card .insights-card-caption:hover:after {
          transform-origin: left center;
          transform: scaleX(1); }
      .insights-section .insights-card.card .insights-card-caption .title {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 15px; }
      .insights-section .insights-card.card .insights-card-caption .action-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between; }
        .insights-section .insights-card.card .insights-card-caption .action-wrap .text {
          color: #96A5AB; }
  .insights-section .insights-card-primary.card {
    text-align: center;
    background: #EF4036;
    border-radius: 0;
    border: 0;
    margin-bottom: 30px; }
    .insights-section .insights-card-primary.card .card-body {
      padding: 50px; }
    .insights-section .insights-card-primary.card figure {
      margin-bottom: 20px; }
      .insights-section .insights-card-primary.card figure img {
        width: 100px;
        height: 100xp; }
    .insights-section .insights-card-primary.card .title {
      font-size: 28px;
      line-height: 1.3;
      font-weight: 700;
      color: #fff;
      margin: 0 20px 15px; }
    .insights-section .insights-card-primary.card .btn {
      background: #fff;
      color: #007eff;
      border-color: #fff;
      border-radius: 10px;
      text-transform: uppercase;
      font-weight: 700;
      min-width: 145px;
      font-size: 15px;
      letter-spacing: 2px;
      line-height: 1.8; }
