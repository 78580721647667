/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Thu Nov 04 2021
 * Category: Pages
 * Title: TechnologiesSection
 *
 */
.technologies-section {
  padding: 80px 0 40px;
  border-bottom: 1px solid #E3E8EA; }
  @media (max-width: 480px) {
    .technologies-section {
      padding: 40px 0; } }
  .technologies-section .main-heading {
    margin-bottom: 30px; }
  @media (max-width: 991px) {
    .technologies-section .tabs-wrap .nav.nav-tabs .nav-link {
      padding: 25px 20px; } }
  @media (max-width: 771px) {
    .technologies-section .tabs-wrap .nav.nav-tabs .nav-link {
      padding: 25px 15px; } }
  .technologies-section .tabs-wrap .tab-content {
    margin-top: 30px; }
    @media (max-width: 480px) {
      .technologies-section .tabs-wrap .tab-content {
        margin-bottom: -40px; } }
  .technologies-section .tech-box {
    display: inline-block;
    text-align: center;
    margin-bottom: 50px; }
    @media (max-width: 767px) {
      .technologies-section .tech-box {
        margin-bottom: 40px; } }
    .technologies-section .tech-box .icon-wrap {
      margin-bottom: 25px; }
      @media (max-width: 991px) {
        .technologies-section .tech-box .icon-wrap {
          margin-bottom: 15px; }
          .technologies-section .tech-box .icon-wrap svg {
            width: 30px;
            height: 40px; } }
      @media (max-width: 767px) {
        .technologies-section .tech-box .icon-wrap svg {
          width: 25px;
          height: 35px; } }
    .technologies-section .tech-box .title {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 0; }
      @media (max-width: 991px) {
        .technologies-section .tech-box .title {
          font-size: 17px; } }
      @media (max-width: 767px) {
        .technologies-section .tech-box .title {
          font-size: 16px; } }
      @media (max-width: 480px) {
        .technologies-section .tech-box .title {
          font-size: 15px; } }
